<template>
  <div class="breadcrumb row justify-between items-center">
    <div class="row items-center breadcrumb-mobile-view">
      <q-btn dense flat icon="keyboard_backspace" @click="$router.go(-1)">
      </q-btn>
      <h2>{{ $props.current }}</h2>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    current: {
      type: String,
      required: true
    }
  }
};
</script>
