<template>
  <div v-if="loadPage" class="pricing">
    <div class="q-pa-md">
      <div class="q-gutter-y-md">
        <q-card>
          <q-tabs
            v-model="tab"
            active-color="primary"
            indicator-color="primary"
            align="justify"
            class="bg-white"
          >
            <q-tab
              v-for="(c, i) in countries"
              :key="i"
              :name="c.name"
              :label="c.name"
              @click="getPricing(c.code)"
            />
          </q-tabs>
          <q-tab-panels v-model="country.area.name" animated>
            <q-tab-panel v-for="(c, i) in countries" :key="i" :name="c.name">
              <div class="q-pa-md">
                <q-select
                  class="q-my-lg"
                  rounded
                  :options="currencies"
                  option-label="code"
                  v-model="country.currency"
                  label="Currency"
                  @input="onCurrencyChange"
                />
              </div>
              <div class="row">
                <div class="col-md-4 col-12 text-center text-primary text-bold">
                  Application
                  <div class="q-pa-md">
                    <q-input
                      v-model="country.firstClass"
                      label="First class price"
                    />
                  </div>
                  <div class="q-pa-md">
                    <q-input
                      v-model="country.eachSubsequentClass"
                      label="Each subsequent class price"
                    />
                  </div>
                  <div
                    class="q-pa-md"
                    v-if="
                      country.area.code === 'NZ' || country.area.code === 'AU'
                    "
                  >
                    <q-input
                      v-model="country.firstClassBespoke"
                      label="First class price (bespoke)"
                    />
                  </div>
                  <div
                    class="q-pa-md"
                    v-if="
                      country.area.code === 'NZ' || country.area.code === 'AU'
                    "
                  >
                    <q-input
                      v-model="country.eachSubsequentClassBespoke"
                      label="Each subsequent class price (bespoke)"
                    />
                  </div>
                </div>
                <div class="col-md-4 col-12 text-center text-primary text-bold">
                  Renewal
                  <div class="q-pa-md">
                    <q-input
                      v-model="country.renewalPerClass"
                      label="Per class price"
                    />
                  </div>
                </div>
                <div class="col-md-4 col-12 text-center text-primary text-bold">
                  Search
                  <div class="q-pa-md">
                    <q-input
                      v-model="country.searchPerClass"
                      label="Per class price"
                    />
                  </div>
                </div>
              </div>
              <div class="q-pa-md">
                <q-btn
                  color="primary"
                  label="Save"
                  @click="updatePricing(country.id)"
                >
                </q-btn>
              </div>
            </q-tab-panel>
          </q-tab-panels>
        </q-card>
      </div>
    </div>
  </div>
</template>

<script>
import { Loading } from "quasar";

export default {
  data() {
    return {
      loadPage: true,
      country: {
        firstClass: null,
        firstClassBespoke: null,
        eachSubsequentClass: null,
        eachSubsequentClassBespoke: null,
        searchPerClass: null,
        renewalPerClass: null,
        customArea: null,
        area: {
          code: "",
          name: ""
        },
        currencyID: null,
        currency: {
          code: "",
          label: ""
        }
      },
      data: [],
      area: "",
      countries: [],
      tab: "New Zealand",
      currency: "",
      currencies: [],
      currencyCodes: []
    };
  },
  created() {
    this.loadPage = false;
    Loading.show();
    this.$http.pricing.getCurrencies().then(res => {
      this.currencies = res.data;
    });
    this.$http.pricing.get().then(res => {
      this.data = res.data;
      this.country = res.data[0];
      this.loadPage = true;
      Loading.hide();
      this.data.filter(item => {
        if (item.area) {
          this.countries.push(item.area);
        } else {
          let european = {
            name: item.customArea,
            code: item.customArea
          };
          item.area = european;
          this.countries.push(european);
        }
      });
    });
  },
  methods: {
    getPricing(id) {
      this.country = this.data.find(data => data.area.code === id);
    },
    onCurrencyChange(val) {
      this.currencies.find(item => {
        if (item.name === val) {
          console.log(item.currency);
          this.country.currency = item.currency;
        }
      });
    },
    updatePricing(id) {
      this.country.currencyID = this.country.currency.id;
      this.$http.pricing
        .edit(id, this.country)
        .then(() => {
          this.$q.notify({
            color: "positive",
            icon: "done",
            position: "top",
            message: "Pricing Updated Successfully",
            timeout: 3000
          });
        })
        .catch(function(err) {
          this.$q.notify({
            color: "negative",
            icon: "warning",
            position: "top",
            message: err.response.data.message,
            timeout: 3000
          });
        });
    }
  }
};
</script>
