var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-form',{ref:"passForm",on:{"submit":_vm.setPass}},[_c('div',{staticClass:"form-background"},[_c('div',{staticClass:"form-heading"},[_c('h1',{staticClass:"text-uppercase"},[_vm._v(_vm._s(_vm.$t("routes.create-password")))]),_c('span',{staticClass:"text-body2"},[_vm._v(_vm._s(_vm.$t("routes.please_create_password")))])]),_c('div',{attrs:{"id":"error"}}),_c('div',{staticClass:"form-body"},[_c('div',{staticClass:"form-group"},[_c('q-input',{staticClass:"q-mt-lg form-input text-body2",attrs:{"rounded":"","outlined":"","no-error-icon":"","rules":[
            function (val) { return !!val || 'New password is required'; },
            function (val) { return _vm.validatePassword(val) ||
              'Password must be at least 8 characters, containing 1 capital letter, 1 lowercase letter, 1 number and 1 special symbol.'; }
          ],"type":_vm.isPwd2 ? 'password' : 'text',"placeholder":_vm.$t('settings.new_password')},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":_vm.isPwd2 ? 'visibility_off' : 'visibility'},on:{"click":function($event){_vm.isPwd2 = !_vm.isPwd2}}})]},proxy:true}]),model:{value:(_vm.passwordForm.newPassword),callback:function ($$v) {_vm.$set(_vm.passwordForm, "newPassword", $$v)},expression:"passwordForm.newPassword"}})],1),_c('div',{staticClass:"form-group"},[_c('q-input',{staticClass:"q-mt-lg form-input text-body2",attrs:{"rounded":"","outlined":"","no-error-icon":"","rules":[
            function (val) { return !!val || 'Confirm password is required'; },
            function (val) { return (val &&
                _vm.validatePassword(val) &&
                val === _vm.passwordForm.newPassword) ||
              'Please make sure your passwords match'; }
          ],"type":_vm.isPwd3 ? 'password' : 'text',"placeholder":_vm.$t('settings.confirm_password')},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('q-icon',{staticClass:"cursor-pointer",attrs:{"name":_vm.isPwd3 ? 'visibility_off' : 'visibility'},on:{"click":function($event){_vm.isPwd3 = !_vm.isPwd3}}})]},proxy:true}]),model:{value:(_vm.passwordForm.confirmNewPassword),callback:function ($$v) {_vm.$set(_vm.passwordForm, "confirmNewPassword", $$v)},expression:"passwordForm.confirmNewPassword"}})],1),_c('div',{staticClass:"form-actions row justify-between items-center"},[_c('q-btn',{staticClass:"btn-sign-in bg-white text-primary text-weight-medium",attrs:{"outline":"","rounded":"","label":_vm.$t('submit'),"type":"submit"}})],1)])])])}
var staticRenderFns = []

export { render, staticRenderFns }