<template>
  <q-form @submit="setPass" ref="passForm">
    <div class="form-background">
      <div class="form-heading">
        <h1 class="text-uppercase">{{ $t("routes.create-password") }}</h1>
        <span class="text-body2">{{
          $t("routes.please_create_password")
        }}</span>
      </div>
      <div id="error"></div>
      <div class="form-body">
        <div class="form-group">
          <q-input
            v-model="passwordForm.newPassword"
            class="q-mt-lg form-input text-body2"
            rounded
            outlined
            no-error-icon
            :rules="[
              val => !!val || 'New password is required',
              val =>
                validatePassword(val) ||
                'Password must be at least 8 characters, containing 1 capital letter, 1 lowercase letter, 1 number and 1 special symbol.'
            ]"
            :type="isPwd2 ? 'password' : 'text'"
            :placeholder="$t('settings.new_password')"
          >
            <template v-slot:append>
              <q-icon
                :name="isPwd2 ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwd2 = !isPwd2"
              />
            </template>
          </q-input>
        </div>
        <div class="form-group">
          <q-input
            v-model="passwordForm.confirmNewPassword"
            class="q-mt-lg form-input text-body2"
            rounded
            outlined
            no-error-icon
            :rules="[
              val => !!val || 'Confirm password is required',
              val =>
                (val &&
                  validatePassword(val) &&
                  val === passwordForm.newPassword) ||
                'Please make sure your passwords match'
            ]"
            :type="isPwd3 ? 'password' : 'text'"
            :placeholder="$t('settings.confirm_password')"
          >
            <template v-slot:append>
              <q-icon
                :name="isPwd3 ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwd3 = !isPwd3"
              />
            </template>
          </q-input>
        </div>
        <div class="form-actions row justify-between items-center">
          <q-btn
            outline
            rounded
            class="btn-sign-in bg-white text-primary text-weight-medium"
            :label="$t('submit')"
            type="submit"
          />
        </div>
      </div>
    </div>
  </q-form>
</template>

<script>
export default {
  data() {
    return {
      user: null,
      isPwd2: true,
      isPwd3: true,
      passwordForm: {
        newPassword: "",
        confirmNewPassword: ""
      }
    };
  },
  methods: {
    validatePassword(pass) {
      const reg = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
      );
      return reg.test(pass);
    },
    setPass() {
      const self = this;
      self.$refs.passForm.validate().then(success => {
        if (success) {
          console.log(success);
          self.$http.users
            .changePass(self.passwordForm)
            .then(function() {
              self.$q.notify({
                color: "positive",
                icon: "done",
                position: "top",
                message: "Your Password has been changed!",
                timeout: 3000
              });
              self.$store.commit("USER_ACTIVATION", true);
              self.$router.push("/trademarks");
            })
            .catch(function(err) {
              self.$q.notify({
                color: "negative",
                icon: "warning",
                position: "top",
                message: err.response.data.message,
                timeout: 3000
              });
            });
        } else {
          self.$q.notify({
            color: "negative",
            icon: "warning",
            position: "top",
            message: "Invalid new password or confirm password!",
            timeout: 3000
          });
        }
      });
    }
  }
};
</script>
