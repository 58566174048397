<template>
  <div class="pos-relative">
    <breadcrumb current="Edit User"> </breadcrumb>
    <div class="add-user-form q-pa-md q-mt-md">
      <template>
        <div class="q-pt-sm">
          <div class="q-gutter-md">
            <q-select
              filled
              v-model="type"
              :options="type_options"
              label="Type"
              ref="userTypeRef"
              @input="changeTypeValue"
              :rules="[required]"
            />
          </div>
        </div>
        <div class="q-pt-sm" v-if="user.profile.type === 'person'">
          <div class="q-gutter-md">
            <q-select
              filled
              v-model="title"
              :options="titles"
              label="Title"
              ref="title"
              @input="changeTitle"
              :rules="[requiredByTypeRule('Person')]"
            />
          </div>
        </div>
        <div class="q-pt-sm">
          <div class="q-gutter-md">
            <q-select
              filled
              v-model="user.profile.address.country"
              :options="countries"
              ref="country"
              option-label="name"
              label="Country"
              :rules="[required]"
              @input="selectCountry"
            />
          </div>
        </div>
        <div class="q-pt-sm " v-if="user.profile.address.country">
          <div class="q-gutter-md">
            <q-select
              filled
              class="region"
              v-model="user.profile.address.region"
              :options="regions"
              option-label="name"
              ref="region"
              label="Region"
              @input="selectRegion"
            />
          </div>
        </div>
        <div class="q-pt-sm" v-if="user.profile.type === 'person'">
          <div class="q-gutter-md">
            <q-input
              filled
              v-model="user.profile.firstName"
              label="Firstname"
              ref="firstName"
              :rules="[requiredByTypeRule('Person')]"
            />
          </div>
        </div>
        <div class="q-pt-sm" v-if="user.profile.type === 'person'">
          <div class="q-gutter-md">
            <q-input
              filled
              v-model="user.profile.lastName"
              label="Lastname"
              :rules="[requiredByTypeRule('Person')]"
              ref="lastName"
            />
          </div>
        </div>
        <div class="q-pt-sm" v-if="user.profile.type === 'business'">
          <div class="q-gutter-md">
            <q-input
              filled
              v-model="user.profile.ownerName"
              label="Ownername"
              ref="ownerName"
              :rules="[requiredByTypeRule('Business')]"
            />
          </div>
        </div>
        <div class="q-pt-sm">
          <div class="q-gutter-md">
            <q-input
              filled
              v-model="user.email"
              type="email"
              label="Contact Email"
              ref="email"
              :rules="[required, email]"
            >
              <template v-slot:before>
                <q-icon name="mail" />
              </template>
            </q-input>
          </div>
        </div>
        <div class="q-pt-sm">
          <div class="q-gutter-md">
            <q-input
              filled
              v-model="user.profile.phone"
              label="Phone"
              ref="phone"
              :rules="[required, phone]"
            />
          </div>
        </div>
        <div class="q-pt-sm">
          <div class="q-gutter-md">
            <q-input
              filled
              v-model="user.profile.address.address"
              label="Address"
              ref="address"
              :rules="[required]"
            />
          </div>
        </div>
        <div class="q-pt-sm">
          <div class="q-gutter-md">
            <q-input
              filled
              v-model="user.profile.address.postCode"
              label="Post Code"
              ref="postCode"
              :rules="[required]"
            />
          </div>
        </div>
        <div class="q-pt-sm">
          <div class="q-gutter-md">
            <q-input
              filled
              v-model="user.profile.address.city"
              label="Town/City"
              ref="city"
              :rules="[required]"
            />
          </div>
        </div>

        <div class="q-pt-sm">
          <div class="q-gutter-md">
            <q-toggle
              v-model="user.profile.isPostalAddressSame"
              label="Postal address same as above?"
              ref="postalAddress"
            />
          </div>
        </div>
        <div v-if="!user.profile.isPostalAddressSame">
          <div class="q-pt-sm">
            <div class="q-gutter-md">
              <q-input
                filled
                v-model="user.profile.postalAddress.address"
                label="Postal Address"
              />
            </div>
          </div>
          <div class="q-pt-sm">
            <div class="q-gutter-md">
              <q-input
                filled
                v-model="user.profile.postalAddress.postCode"
                label="Postal Post Code"
                :rules="[requiredByPostalAddressRule]"
              />
            </div>
          </div>
          <div class="q-pt-sm">
            <div class="q-gutter-md">
              <q-input
                filled
                v-model="user.profile.postalAddress.city"
                label="Postal Town/City"
                :rules="[requiredByPostalAddressRule]"
              />
            </div>
          </div>
          <div class="q-pt-sm">
            <div class="q-gutter-md">
              <q-select
                filled
                v-model="user.profile.postalAddress.country"
                :options="countries"
                option-label="name"
                label="Postal Country"
                :rules="[requiredByPostalAddressRule]"
              />
            </div>
          </div>
        </div>
        <div class="q-pt-sm add-user-btn">
          <q-btn
            class="add-user"
            color="primary"
            label="Update User"
            no-caps
            @click="updateUser()"
          />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import {
  phone,
  email,
  required,
  requiredByType,
  requiredByPostalAddress
} from "../utils/validations";

export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      userId: null,
      confirm_password: null,
      requiredByTypeRule: () => {},
      requiredByPostalAddressRule: () => {},
      user: {
        email: "",
        password: "",
        profile: {
          title: "",
          type: "business",
          firstName: "",
          lastName: "",
          ownerName: "",
          phone: "",
          isPostalAddressSame: true,
          abn: "",
          address: {
            address: "",
            postCode: "",
            countryId: null,
            regionId: null,
            city: ""
          },
          postalAddress: {
            address: "",
            postCode: "",
            countryId: null,
            regionId: null,
            city: ""
          }
        }
      },
      countries: [],
      title: "",
      titles: [
        {
          label: "MR",
          value: "MR"
        },
        {
          label: "MS",
          value: "MS"
        },
        {
          label: "MRS",
          value: "MRS"
        },
        {
          label: "MISS",
          value: "MISS"
        },
        {
          label: "MX",
          value: "MX"
        },
        {
          label: "MASTER",
          value: "MASTER"
        }
      ],
      regions: [],
      type: null,
      type_options: [
        {
          label: "Business",
          value: "business"
        },
        {
          label: "Person",
          value: "person"
        }
      ]
    };
  },
  created() {
    this.requiredByTypeRule = requiredByType.bind(this);
    this.requiredByPostalAddressRule = requiredByPostalAddress.bind(this);
    this.$http.users.getCountries().then(res => {
      this.countries = res.data;
    });
    this.userId = this.$route.params.id;
    this.$http.users.getById(this.userId).then(res => {
      this.user = res.data;
      if (!this.user.profile.postalAddress) {
        this.user.profile.postalAddress = {
          address: "",
          postCode: "",
          countryId: null,
          regionId: null,
          city: ""
        };
      }
      this.titles.find(title => {
        if (title.value === res.data.profile.title) {
          this.title = title.label;
        }
      });
      this.type_options.find(type => {
        if (type.value === res.data.profile.type) {
          this.type = type.label;
        }
      });
    });
  },
  methods: {
    email,
    required,
    phone,
    async updateUser() {
      this.$refs.userTypeRef.validate();
      this.$refs.country.validate();
      if (this.user.profile.type === "person") {
        this.$refs.title.validate();
        this.$refs.firstName.validate();
        this.$refs.lastName.validate();
      }
      if (this.user.profile.type === "business") {
        this.$refs.ownerName.validate();
      }
      if (this.user.profile.isPostalAddressSame) {
        this.user.profile.postalAddress = {
          address: "",
          postCode: "",
          countryId: null,
          regionId: null,
          city: ""
        };
      }
      this.$refs.email.validate();
      this.$refs.phone.validate();
      this.$refs.address.validate();
      this.$refs.postCode.validate();
      this.$refs.city.validate();
      if (this.user.profile.address.region) {
        this.user.profile.address.regionId = this.user.profile.address.region.id;
      }

      delete this.user.role;
      try {
        this.$http.users.edit(this.userId, this.user);
        this.$q.notify({
          color: "positive",
          icon: "done",
          position: "top",
          message: "User Updated Successfully",
          timeout: 1000
        });
      } catch (err) {
        this.$q.notify({
          color: "negative",
          icon: "warning",
          position: "top",
          message: "Something went wrong",
          timeout: 1000
        });
      }
    },
    changeTitle(val) {
      this.user.profile.title = val.value;
      this.title = val.label;
    },
    changeTypeValue(val) {
      this.user.profile.type = val.value;
      this.type = val.label;
    },
    selectCountry(country) {
      this.user.profile.address.regionId = null;
      this.user.profile.address.region = null;
      this.regions = country.regions;
    },
    selectRegion(region) {
      this.user.profile.address.regionId = region.id;
    }
  }
};
</script>
