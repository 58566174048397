<template>
  <div class="pos-relative">
    <breadcrumb current="Single Application"> </breadcrumb>
    <div class="single-trademark q-pa-lg-md">
      <template>
        <div class="q-pa-lg-md field-card mb-5">
          <q-list class="tag-items" v-if="trademark.image">
            <q-item-label header>Image</q-item-label>
            <q-item>
              <q-item-section>
                <img
                  class="trademark-single-img"
                  :src="baseURL + `${trademark.image.thumbnail}`"
                  alt="Trademark"
                />
              </q-item-section>
            </q-item>
          </q-list>
          <q-list class="tag-items" v-if="trademark.word">
            <q-item-label header>Word</q-item-label>
            <q-item>
              <q-item-section>
                <q-item-label>{{ trademark.word }}</q-item-label>
              </q-item-section>
            </q-item>
          </q-list>
          <q-list class="tag-items">
            <q-item-label header>User</q-item-label>
            <q-item>
              <q-item-section>
                <q-item-label
                  v-if="
                    trademark.user && trademark.user.profile.type === 'person'
                  "
                  >{{ trademark.user.profile.firstName }}
                  {{ trademark.user.profile.lastName }}</q-item-label
                >
                <q-item-label v-else>{{
                  trademark.user ? trademark.user.profile.ownerName : null
                }}</q-item-label>
                <q-item-label
                  ><span class="field-name">Email:</span>
                  {{
                    trademark.user ? trademark.user.email : null
                  }}</q-item-label
                >
              </q-item-section>
            </q-item>
          </q-list>
          <q-list
            class="tag-items"
            v-if="trademark.tags && trademark.tags.length > 0"
          >
            <q-item-label header>Tags</q-item-label>
            <q-item v-for="tag in trademark.tags" v-bind:key="tag.id">
              <q-item-section>
                <q-item-label>{{ tag.label }}</q-item-label>
                <q-item-label
                  ><span class="field-name">Weight:</span>
                  {{ tag.weight }}</q-item-label
                >
              </q-item-section>
            </q-item>
          </q-list>
          <q-list class="tag-items">
            <q-item-label header>Country</q-item-label>
            <q-item>
              <q-item-section>
                <q-item-label>{{
                  trademark.country ? trademark.country.name : null
                }}</q-item-label>
                <q-item-label
                  ><span class="field-name">Code:</span>
                  {{
                    trademark.country ? trademark.country.code : null
                  }}</q-item-label
                >
              </q-item-section>
            </q-item>
          </q-list>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";

export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      trademark: {},
      baseURL: process.env.VUE_APP_API_URL
    };
  },
  created() {
    this.$http.trademarks.getById(this.$route.params.id).then(res => {
      this.trademark = res.data;
    });
  }
};
</script>
