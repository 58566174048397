import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Users from "@/views/Users.vue";
// import Dashboard from "@/views/Dashboard.vue";
import Settings from "@/views/Settings.vue";
import Profile from "@/views/Profile.vue";
import Login from "@/views/Login.vue";
import NotFound from "@/views/NotFound.vue";
import CreatePassword from "@/views/CreatePassword";
import Pricing from "@/views/Pricing.vue";
import Tags from "@/views/Tags.vue";
import AddNewUser from "@/views/AddNewUser";
import EditUser from "@/views/EditUser";
import Trademarks from "@/views/Trademarks";
import Renewals from "@/views/Renewals";
import SingleTrademark from "../views/SingleTrademark";
import Coupons from "../views/Coupons";
import AddNewCoupon from "../views/AddNewCoupon";
import EditCoupon from "../views/EditCoupon";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "trademarks",
    redirect: "/trademarks"
  },
  // {
  //   path: "/dashboard",
  //   name: "dashboard",
  //   component: Dashboard,
  //   meta: { authorizedRoles: ["Admin", "Viewer", "Editor"] }
  // },
  {
    path: "/settings",
    name: "settings",
    component: Settings,
    meta: { authorizedRoles: ["Admin"] }
  },
  {
    path: "/renewals",
    name: "renewals",
    component: Renewals,
    meta: { authorizedRoles: ["Admin", "Editor", "Viewer"] }
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    meta: { authorizedRoles: ["Admin", "Editor", "Viewer"], params: {} }
  },
  {
    path: "/users",
    name: "users",
    component: Users,
    meta: { authorizedRoles: ["Admin", "Editor", "Viewer"] }
  },
  {
    path: "/users/add-user",
    name: "Add New User",
    component: AddNewUser,
    props: true,
    meta: { authorizedRoles: ["Admin", "Editor"] }
  },
  {
    path: "/users/:id",
    name: "Edit User",
    component: EditUser,
    props: true,
    meta: { authorizedRoles: ["Admin", "Editor"] }
  },
  {
    path: "/pricing",
    name: "pricing",
    component: Pricing,
    meta: { authorizedRoles: ["Admin", "Editor", "Viewer"] }
  },
  {
    path: "/tags",
    name: "tags",
    component: Tags,
    meta: { authorizedRoles: ["Admin", "Editor", "Viewer"] }
  },
  {
    path: "/trademarks",
    name: "trademarks",
    component: Trademarks,
    meta: { authorizedRoles: ["Admin", "Editor", "Viewer"] }
  },
  {
    path: "/trademarks/:id",
    name: "singleTrademark",
    component: SingleTrademark,
    meta: { authorizedRoles: ["Admin", "Editor", "Viewer"] }
  },
  {
    path: "/coupons",
    name: "coupons",
    component: Coupons,
    meta: { authorizedRoles: ["Admin", "Editor", "Viewer"] }
  },
  {
    path: "/coupons/add-coupon",
    name: "Add New Coupon",
    component: AddNewCoupon,
    props: true,
    meta: { authorizedRoles: ["Admin", "Editor"] }
  },
  {
    path: "/coupons/:id",
    name: "Edit Coupon",
    component: EditCoupon,
    props: true,
    meta: { authorizedRoles: ["Admin", "Editor"] }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { authorizedRoles: [] }
  },
  {
    path: "/create-password",
    name: "Create Password",
    component: CreatePassword,
    meta: { authorizedRoles: [] }
  },
  {
    path: "*",
    name: "Not Found",
    component: NotFound,
    meta: { authorizedRoles: ["Admin", "Editor", "Viewer"] }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  const { authorizedRoles } = to.meta;
  let isLoggedIn = store.state.accessToken;
  if (!isLoggedIn && to.path !== "/login") {
    next({ path: "/login" });
  } else if (isLoggedIn && to.path === "/login") {
    next({ path: "/trademarks" });
  } else if (
    authorizedRoles.length &&
    !authorizedRoles.includes(store.state.role)
  ) {
    next({ path: "/trademarks" });
  }

  next();
});

export default router;
