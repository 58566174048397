<template>
  <q-form @submit="login">
    <div class="form-background">
      <div class="form-heading">
        <h1 class="text-uppercase">{{ $t("login.sign_in") }}</h1>
        <h3 class="q-mb-none">{{ $t("login.welcome") }}</h3>
        <span class="text-body2">{{ $t("login.please_sign_in") }}</span>
      </div>
      <div class="form-body">
        <div class="form-group">
          <q-input
            v-model="form.email"
            class="q-mt-lg form-input text-body2"
            rounded
            outlined
            type="email"
            :placeholder="$t('login.email_placeholder')"
          />
        </div>
        <div class="form-group">
          <q-input
            v-model="form.password"
            class="q-mt-md form-input text-body2"
            outlined
            rounded
            :type="isPwd ? 'password' : 'text'"
            :placeholder="$t('login.password_placeholder')"
          >
            <template v-slot:append>
              <q-icon
                :name="isPwd ? 'visibility_off' : 'visibility'"
                class="cursor-pointer"
                @click="isPwd = !isPwd"
              />
            </template>
          </q-input>
        </div>
        <div class="form-actions row justify-between items-center">
          <span
            class="text-weight-medium text-body2 cursor-pointer"
            @click="$emit('open-modal')"
            @keypress.enter="$emit('open-modal')"
            tabindex="0"
            >{{ $t("login.forgot_pass.short") }}</span
          >
          <q-btn
            outline
            rounded
            class="btn-sign-in bg-white text-primary text-weight-medium"
            :label="$t('login.sign_in')"
            type="submit"
          />
        </div>
      </div>
    </div>
  </q-form>
</template>

<script>
export default {
  data() {
    return {
      isPwd: true,
      form: {
        email: "",
        password: ""
      }
    };
  },
  methods: {
    login() {
      const self = this;
      this.$http.auth
        .login(this.form)
        .then(function(res) {
          if (res.data.user.role.key != "admin") {
            throw new Error("User's role must be an admin.");
          }

          self.$store.commit("USER_LOGIN", res.data);
          self.$router.push("/trademarks");
        })
        .catch(function() {
          self.$q.notify({
            color: "negative",
            icon: "warning",
            position: "top",
            message: "Invalid Credentials",
            timeout: 1000
          });
        });
    }
  }
};
</script>
