<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16.523"
    height="16.5"
    viewBox="0 0 16.523 16.5"
  >
    <g
      id="Group_55215"
      data-name="Group 55215"
      transform="translate(-908.865 -353.28)"
      style="isolation: isolate"
    >
      <path
        id="Path_55962"
        data-name="Path 55962"
        d="M920.983,354.247a8.163,8.163,0,0,1,2.879,2.535l-1.117.795a6.88,6.88,0,0,0-9.109-1.976,6.682,6.682,0,0,0-2.449,2.449,6.876,6.876,0,0,0,11.558,7.433l1.117.8a8.143,8.143,0,0,1-2.879,2.535,8.059,8.059,0,0,1-3.867.967,8.188,8.188,0,0,1-4.168-1.117,8.248,8.248,0,0,1-2.943-11.3,7.851,7.851,0,0,1,2.943-2.943,8.2,8.2,0,0,1,8.035-.172Zm3.932,6.789.473.494-.473.494-2.75,2.75-.988-.988,1.568-1.568h-8.379v-1.375h8.379l-1.568-1.569.988-.988Z"
        fill="#8181a5"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>
