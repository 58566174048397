<template>
  <div class="profile pos-relative">
    <breadcrumb class="breadcrumb-profile" current="Profile"> </breadcrumb>
    <div v-if="loadPage" class="row justify-lg-between profile-section">
      <div class="col-12 col-md-6">
        <div class="items-center profile-card pos-relative">
          <div class="card-heading">{{ $store.state.user.role.label }}</div>
          <div class="row items-center card-body">
            <img src="@/assets/profile_dark.svg" alt="Profile" />
            <div class="q-ml-lg credentials">
              <div>
                <b>{{
                  $store.state.user.profile.firstName &&
                  $store.state.user.profile.lastName
                    ? $store.state.user.profile.firstName +
                      " " +
                      $store.state.user.profile.lastName
                    : null
                }}</b>
              </div>
              <div>
                {{ $store.state.user.email ? $store.state.user.email : null }}
              </div>
              <div class="created-at">
                {{
                  $store.state.user.createdAt
                    ? $t("creation_date") +
                      ": " +
                      getDate($store.state.user.createdAt)
                    : null
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-6">
        <div class="password-card">
          <h3 class="q-my-none text-weight-medium">
            {{ $t("settings.change_password") }}
          </h3>
          <p class="validation-text q-mt-xs">
            {{ $t("settings.password_validation") }}
          </p>
          <q-form ref="passForm" class="pass-form">
            <div class="row q-col-gutter-md">
              <div class="col-12">
                <q-input
                  v-model="passwordForm.oldPassword"
                  class="q-mt-md"
                  outlined
                  :rules="[
                    (val) => validatePassword(val) || 'Please type something',
                  ]"
                  :type="isPwd1 ? 'password' : 'text'"
                  :placeholder="$t('settings.current_password')"
                >
                  <template v-slot:prepend>
                    <img src="@/assets/lock.svg" alt="Lock" />
                  </template>
                  <template v-slot:append>
                    <q-icon
                      :name="isPwd1 ? 'visibility_off' : 'visibility'"
                      class="cursor-pointer"
                      @click="isPwd1 = !isPwd1"
                    />
                  </template>
                </q-input>
              </div>
              <div class="col-12">
                <q-input
                  v-model="passwordForm.newPassword"
                  outlined
                  :rules="[
                    (val) => validatePassword(val) || 'Please type something',
                  ]"
                  :type="isPwd2 ? 'password' : 'text'"
                  :placeholder="$t('settings.new_password')"
                >
                  <template v-slot:prepend>
                    <img src="@/assets/lock.svg" alt="Lock" />
                  </template>
                  <template v-slot:append>
                    <q-icon
                      :name="isPwd2 ? 'visibility_off' : 'visibility'"
                      class="cursor-pointer"
                      @click="isPwd2 = !isPwd2"
                    />
                  </template>
                </q-input>
              </div>
              <div class="col-12">
                <q-input
                  v-model="passwordForm.confirmNewPassword"
                  outlined
                  :rules="[
                    (val) =>
                      (val &&
                        validatePassword(val) &&
                        val === passwordForm.newPassword) ||
                      'Please type something',
                  ]"
                  :type="isPwd3 ? 'password' : 'text'"
                  :placeholder="$t('settings.confirm_password')"
                >
                  <template v-slot:prepend>
                    <img src="@/assets/lock.svg" alt="Lock" />
                  </template>
                  <template v-slot:append>
                    <q-icon
                      :name="isPwd3 ? 'visibility_off' : 'visibility'"
                      class="cursor-pointer"
                      @click="isPwd3 = !isPwd3"
                    />
                  </template>
                </q-input>
              </div>
            </div>
            <q-btn
              class="float-right action-button q-my-md"
              color="primary"
              :label="$t('save')"
              @click="setPass"
            />
          </q-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import { Loading } from "quasar";
import moment from "moment";
export default {
  components: {
    Breadcrumb,
  },
  data() {
    return {
      loadPage: true,
      framework: {
        plugins: ["Loading"],
        config: {
          loading: {},
        },
      },
      isPwd1: true,
      isPwd2: true,
      isPwd3: true,
      user: {
        profile: {},
      },
      passwordForm: {
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      },
    };
  },
  created() {
    this.loadPage = false;
    Loading.show();
    this.$http.users.getById(this.$store.state.user.id).then((res) => {
      this.user = res.data;
      this.loadPage = true;
      Loading.hide();
    });
  },
  methods: {
    getDate(dt) {
      const date = new Date(dt);
      return moment(date).format("DD.MM.YYYY");
    },
    validatePassword(pass) {
      const reg = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
      );
      return reg.test(pass);
    },
    setPass() {
      const self = this;
      this.$refs.passForm.validate().then((success) => {
        if (success) {
          self.$http.users
            .changePass(self.passwordForm)
            .then(function () {
              self.$q.notify({
                color: "positive",
                icon: "done",
                position: "top",
                message: "Your Password has been changed!",
                timeout: 3000,
              });
            })
            .catch(function (err) {
              self.$q.notify({
                color: "negative",
                icon: "warning",
                position: "top",
                message: err.response.data.message,
                timeout: 3000,
              });
            });
        }
      });
    },
  },
};
</script>
