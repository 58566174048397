<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.875"
    height="18.563"
    viewBox="0 0 17.875 18.563"
  >
    <g
      id="Group_55216"
      data-name="Group 55216"
      transform="translate(-878.24 -380.968)"
      style="isolation: isolate"
    >
      <path
        id="Path_55963"
        data-name="Path 55963"
        d="M887.533,381.612a4.782,4.782,0,0,1,1.751,1.751,4.885,4.885,0,0,1,.075,4.716A4.8,4.8,0,0,1,887.8,389.8l.408.172a2.869,2.869,0,0,0-.43.558,5.116,5.116,0,0,0-.322.6v.021a5.087,5.087,0,0,0-2.342-.558,5.355,5.355,0,0,0-2.75.741,5.521,5.521,0,0,0-2.009,2.009,5.352,5.352,0,0,0-.741,2.75h-1.375a6.818,6.818,0,0,1,2-4.834,7.068,7.068,0,0,1,2.17-1.483,4.872,4.872,0,0,1-1.536-1.708,4.871,4.871,0,0,1,.075-4.7,4.782,4.782,0,0,1,1.751-1.751,4.858,4.858,0,0,1,4.834,0Zm-4.146,1.193a3.454,3.454,0,0,0-1.246,1.246,3.468,3.468,0,0,0,0,3.459,3.454,3.454,0,0,0,1.246,1.246,3.468,3.468,0,0,0,3.459,0,3.469,3.469,0,0,0,1.246-1.246,3.468,3.468,0,0,0,0-3.459,3.469,3.469,0,0,0-1.246-1.246,3.468,3.468,0,0,0-3.459,0Zm9.292,7.476a2.912,2.912,0,0,1,1,1,2.619,2.619,0,0,1,.376,1.375v1.375h2.062v5.5h-9.625v-5.5h2.063v-1.375a2.61,2.61,0,0,1,.376-1.375,2.9,2.9,0,0,1,1-1,2.7,2.7,0,0,1,2.75,0Zm-4.813,7.874h6.875V395.4h-6.875Zm2.471-6.467a1.321,1.321,0,0,0-.408.967v1.375h2.75v-1.375a1.368,1.368,0,0,0-2.342-.967Z"
        fill="#8181a5"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>
