<template>
  <div class="tags">
    <div class="options">
      <div class="q-pa-lg-md q-py-md">
        <div class="row justify-between items-center">
          <div class="q-gutter-md row">
            <q-select
              filled
              v-model="tag"
              :options="filter_options"
              label="Filter"
            />
            <q-input filled v-model="text" label="Text" />
            <q-btn
              class="search-btn"
              color="primary"
              label="Search"
              no-caps
              @click="searchTags"
            />
          </div>
          <q-uploader
            accept=".csv"
            class="q-mt-lg q-mt-lg-none"
            @added="uploadCSV"
            auto-upload
          />
        </div>
      </div>
    </div>
    <q-table
      v-if="showTable"
      title="Tags"
      :filter="filter"
      :data="data"
      :columns="columns"
      row-key="name"
      class="tags-table q-mb-xl"
      :pagination.sync="initialPagination"
      @request="searchTags"
      :loading="loading"
    >
      <template v-slot:body-cell-actions="props">
        <q-td :props="props">
          <div class="actions">
            <q-btn
              dense
              round
              flat
              color="grey"
              class="edit-button"
              icon="edit"
              @click="editRow(props.row.id)"
            ></q-btn>
            <q-btn
              class="delete-button"
              dense
              round
              flat
              color="red"
              @click="deleteRow(props.row.id)"
              icon="delete"
            ></q-btn>
          </div>
        </q-td>
      </template>
    </q-table>
    <q-dialog
      v-model="deletePointsModalVisible"
      transition-show="flip-down"
      transition-hide="flip-up"
    >
      <q-card>
        <q-bar>
          <h3 class="text-white text-uppercase">
            Confirm the action
          </h3>
          <q-space />
          <q-btn dense flat icon="close" color="white" v-close-popup></q-btn>
        </q-bar>
        <q-card-section>
          <q-btn
            class="float-right q-mt-lg q-ml-md"
            :label="$t('cancel')"
            v-close-popup
            outline
            color="primary"
          />
          <q-btn
            class="float-right q-mt-lg"
            color="primary"
            :label="$t('delete')"
            @click="deleteUser()"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog
      v-model="upload_csv"
      transition-show="flip-down"
      transition-hide="flip-up"
    >
      <q-card>
        <q-bar>
          <h3 class="text-white text-uppercase">
            Confirm the action
          </h3>
          <q-space />
          <q-btn dense flat icon="close" color="white" v-close-popup></q-btn>
        </q-bar>
        <q-card-section>
          <q-btn
            class="float-right q-mt-lg q-ml-md"
            :label="$t('cancel')"
            v-close-popup
            outline
            color="primary"
          />
          <q-btn
            class="float-right q-mt-lg"
            color="primary"
            :label="$t('delete')"
            @click="upload()"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog
      v-model="updateTag"
      transition-show="flip-down"
      transition-hide="flip-up"
    >
      <q-card>
        <q-bar>
          <h3 class="text-white text-uppercase">
            Confirm the action
          </h3>
          <q-space />

          <q-btn dense flat icon="close" color="white" v-close-popup></q-btn>
        </q-bar>
        <q-card-section>
          <q-input filled v-model="singleTag.label" label="Description" />
          <q-btn
            class="float-right q-mt-lg"
            color="primary"
            :label="$t('submit')"
            @click="edit()"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      tagID: null,
      initialPagination: {
        descending: false,
        page: 1,
        rowsPerPage: 10,
        rowsNumber: 0
      },
      loading: false,
      showTable: false,
      updateTag: false,
      tag: "",
      deletePointsModalVisible: false,
      text: "",
      upload_csv: false,
      filter_options: [
        {
          label: "Class",
          value: "class"
        },
        {
          label: "Description",
          value: "description"
        }
      ],
      columns: [
        {
          name: "ID",
          required: true,
          label: "ID",
          align: "left",
          field: "id",
          sortable: true
        },

        {
          name: "description",
          required: true,
          label: "Description",
          align: "left",
          field: row => row.label,
          sortable: true
        },
        {
          name: "Class",
          label: "Class",
          field: row => row.class.id
        },
        { name: "actions", label: "Actions", field: "actions" }
      ],
      tagId: null,
      filter: "",
      total: 0,
      singleTag: {
        label: ""
      },
      data: [
        {
          label: "",
          country: {
            code: "",
            name: ""
          },
          class: {
            description: ""
          }
        }
      ],
      pageNumber: 1
    };
  },

  methods: {
    searchTags(props) {
      this.loading = true;
      let page, rowsPerPage, sortBy, descending;

      const pg = props.pagination || {};

      page = pg.page || this.initialPagination.page;
      rowsPerPage = pg.rowsPerPage || this.initialPagination.rowsPerPage;
      sortBy = pg.sortBy || this.initialPagination.sortBy;
      descending = pg.descending || this.initialPagination.descending;

      this.initialPagination.page = page;
      this.initialPagination.rowsPerPage = rowsPerPage;
      this.initialPagination.sortBy = sortBy;
      this.initialPagination.descending = descending;

      if (this.tag.label && this.text) {
        this.showTable = true;
        this.$http.tags
          .get(this.tag.label, this.text, page, rowsPerPage)
          .then(res => {
            console.log(res.data.data);
            this.data = res.data.data;
            this.initialPagination.rowsNumber = res.data.total;
            this.loading = false;
          });
      }
    },
    edit() {
      this.updateTag = false;
      this.$http.tags.updateTag(this.tagId, this.singleTag).then(res => {
        this.singleTag.label = res.data.label;
        this.data.find(p => {
          if (p.id === this.tagId) {
            p.label = this.singleTag.label;
          }
        });
      });
    },
    editRow(id) {
      this.updateTag = true;
      this.$http.tags.getTagById(id).then(res => {
        this.singleTag.label = res.data.label;
      });
      this.tagId = id;
    },
    deleteRow(id) {
      this.deletePointsModalVisible = true;
      this.tagID = id;
    },
    async deleteUser() {
      this.deletePointsModalVisible = false;
      const index = this.data.findIndex(tag => tag.id === this.tagID);
      this.data.splice(index, 1);
      try {
        await this.$http.tags.delete(this.tagID);
        this.$q.notify({
          color: "positive",
          icon: "done",
          position: "top",
          message: "Tag Deleted Successfully",
          timeout: 1000
        });
      } catch (err) {
        this.$q.notify({
          color: "negative",
          icon: "warning",
          position: "top",
          message: err.response.data.message,
          timeout: 1000
        });
      }
    },
    uploadCSV(file) {
      this.upload_csv = true;
      this.uplFile = file;
    },
    async upload() {
      this.upload_csv = false;
      let blob = this.uplFile[0].slice(0, this.uplFile[0].size, "text/csv");
      let newFile = new File([blob], "tag.csv", { type: "text/csv" });
      const fr = new FormData();
      fr.append("file", newFile);
      try {
        await this.$http.tags.uplaodCsv(fr);
        this.$q.notify({
          color: "positive",
          icon: "done",
          position: "top",
          message: "CSV File Successfully Uploaded",
          timeout: 1000
        });
      } catch (err) {
        this.$q.notify({
          color: "negative",
          icon: "warning",
          position: "top",
          message: "Something went wrong",
          timeout: 1000
        });
      }
    }
  }
};
</script>
