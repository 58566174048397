<template>
  <div class="users">
    <template>
      <div class="q-pa-md">
        <q-table
          title="Users"
          :filter="filter"
          :data="data"
          :columns="columns"
          :loading="loading"
          row-key="id"
          class="users-table"
          :pagination.sync="initialPagination"
          @request="getData"
        >
          <template v-slot:loading>
            <q-inner-loading showing color="primary" />
          </template>
          <template v-slot:top-right>
            <q-input
              borderless
              dense
              filled
              debounce="300"
              v-model="filter"
              placeholder="Search"
            >
              <template v-slot:append>
                <q-icon name="search" />
              </template>
            </q-input>
            <q-btn
              class="q-ml-md add-user"
              to="/users/add-user"
              color="primary"
              label="Add New User"
              no-caps
            />
          </template>
          <template v-slot:body-cell-actions="props">
            <q-td :props="props">
              <div class="actions">
                <q-btn
                  dense
                  round
                  flat
                  color="grey"
                  class="edit-button"
                  icon="edit"
                  :to="`/users/${props.row.id}`"
                ></q-btn>
                <q-btn
                  class="delete-button"
                  dense
                  round
                  flat
                  color="red"
                  @click="deleteRow(props.row.id)"
                  icon="delete"
                ></q-btn>
              </div>
            </q-td>
          </template>
        </q-table>
      </div>
    </template>
    <q-dialog
      v-model="deletePointsModalVisible"
      transition-show="flip-down"
      transition-hide="flip-up"
    >
      <q-card>
        <q-bar>
          <h3 class="text-white text-uppercase">Confirm the action</h3>
          <q-space />
          <q-btn dense flat icon="close" color="white" v-close-popup> </q-btn>
        </q-bar>
        <q-card-section>
          <q-btn
            class="float-right q-mt-lg q-ml-md"
            :label="$t('cancel')"
            v-close-popup
            outline
            color="primary"
          />
          <q-btn
            class="float-right q-mt-lg"
            color="primary"
            :label="$t('delete')"
            @click="deleteUser()"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { RequestQueryBuilder } from "@nestjsx/crud-request";

export default {
  data() {
    return {
      filter: "",
      loading: false,
      userID: null,
      deletePointsModalVisible: false,
      columns: [
        {
          name: "id",
          required: true,
          label: "ID",
          align: "left",
          field: "id",
          sortable: true,
        },
        {
          name: "type",
          align: "center",
          label: "Type",
          field: (row) => row.profile.type,
          sortable: true,
        },
        {
          name: "firstName",
          required: true,
          label: "Name",
          align: "left",
          field: (row) => row.profile.firstName + " " + row.profile.lastName,
          sortable: true,
        },
        { name: "email", label: "Email", field: "email", sortable: true },
        {
          name: "city",
          label: "City",
          field: (row) => row.profile.address.city,
        },
        {
          name: "address",
          label: "Address",
          field: (row) => row.profile.address.address,
        },
        { name: "actions", label: "Actions", field: "actions" },
      ],
      initialPagination: {
        page: 1,
        rowsNumber: 0,
        total: 0,
        rowsPerPage: 10,
        pageCount: 2,
      },
      data: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData(props) {
      this.loading = true;
      const qb = RequestQueryBuilder.create();
      let page, rowsPerPage, pageCount;
      const pg = props ? props.pagination : {};
      page = pg.page || this.initialPagination.page;
      rowsPerPage =
        pg.rowsPerPage != undefined
          ? pg.rowsPerPage
          : this.initialPagination.rowsPerPage;
      pageCount = pg.pageCount || this.initialPagination.pageCount;
      this.initialPagination.page = page;
      this.initialPagination.rowsPerPage = rowsPerPage;
      this.initialPagination.pageCount = pageCount;
      if (page) {
        qb.setPage(page);
      }
      if (rowsPerPage) {
        qb.setLimit(rowsPerPage);
      }

      if (this.filter) {
        qb.setFilter({ field: "email", operator: "$cont", value: this.filter });
      }
      qb.sortBy({ field: "id", order: "DESC" });

      this.$http.users.get(qb).then((res) => {
        this.data = res.data.data || res.data;
        this.initialPagination.pageCount = res.data.pageCount;
        this.initialPagination.page = res.data.page;
        this.initialPagination.rowsNumber = res.data.total;
        this.loading = false;
      });
    },
    deleteRow(id) {
      this.userID = id;
      this.deletePointsModalVisible = true;
    },
    async deleteUser() {
      this.deletePointsModalVisible = false;
      const index = this.data.findIndex((user) => user.id === this.userID);
      this.data.splice(index, 1);
      try {
        await this.$http.users.delete(this.userID);
        this.$q.notify({
          color: "positive",
          icon: "done",
          position: "top",
          message: "User Deleted Successfully",
          timeout: 1000,
        });
      } catch (err) {
        this.$q.notify({
          color: "negative",
          icon: "warning",
          position: "top",
          message: err.response.data.message,
          timeout: 1000,
        });
      }
    },
  },
};
</script>
