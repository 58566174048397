<template>
  <q-layout view="lHh Lpr lFf" v-if="$store.state.accessToken">
    <q-header class="bg-white">
      <q-toolbar class="text-dark">
        <q-toolbar-title class="text-uppercase desktop-only">
          {{ $t("layout.overview") }}
        </q-toolbar-title>
        <q-toolbar-title class="mobile-only">
          <q-btn flat @click="leftDrawerOpen = !leftDrawerOpen">
            <img src="@/assets/sidebar.svg" alt="Export" />
          </q-btn>
        </q-toolbar-title>
        <q-space class="desktop-only"></q-space>

        <div class="profile row items-center cursor-pointer q-pl-lg">
          <img src="@/assets/profile.svg" alt="Profile" />
          <div class="q-ml-md">
            <div class="row">
              <div class="full-name">
                {{ $store.state.user.profile.firstName }}
                {{ $store.state.user.profile.lastName }}
              </div>
              <q-icon name="expand_more" />
            </div>
            <span class="email">{{ $store.state.user.email }}</span>
          </div>

          <q-menu
            :offset="[-15, 0]"
            transition-show="flip-down"
            transition-hide="flip-up"
            content-class="profile-dropdown"
            auto-close
          >
            <div class="arrow-up"></div>
            <div class="row no-wrap column">
              <div class="menu-header q-px-sm">
                <div class="text-white">
                  {{ $store.state.user.profile.firstName }}
                  {{ $store.state.user.profile.lastName }}
                </div>
                <span class="text-white">{{ $store.state.user.email }}</span>
              </div>
              <q-list>
                <q-item
                  clickable
                  tag="a"
                  to="/profile"
                  class="profile-link"
                  @click.native="showChildren = false"
                >
                  <q-item-section avatar>
                    <svg-profile></svg-profile>
                  </q-item-section>
                  <q-item-section>{{ $t("layout.profile") }}</q-item-section>
                </q-item>
                <q-item
                  clickable
                  tag="a"
                  to="/settings"
                  class="settings-link"
                  v-if="$store.getters.isAdmin"
                  @click.native="showChildren = false"
                >
                  <q-item-section avatar>
                    <svg-settings></svg-settings>
                  </q-item-section>
                  <q-item-section>{{ $t("layout.settings") }}</q-item-section>
                </q-item>
                <q-item clickable tag="div" @click="logout()">
                  <q-item-section avatar class="q-pt-xs">
                    <svg-turnoff></svg-turnoff>
                  </q-item-section>
                  <q-item-section>{{ $t("layout.signout") }}</q-item-section>
                </q-item>
              </q-list>
            </div>
          </q-menu>
        </div>
      </q-toolbar>
    </q-header>

    <q-drawer v-model="leftDrawerOpen">
      <div class="info-block text-center">
        <img src="@/assets/tmjam-logo.svg" class="q-mb-sm" alt="Tmjam Logo" />
      </div>
      <q-list class="drawer-nav">
        <!-- <q-item clickable tag="a" to="/dashboard" @click="showChildren = false">
          <div class="row">
            <q-item-section avatar>
              <svg-dashboard></svg-dashboard>
            </q-item-section>
            <q-item-section>
              <q-item-label>{{
                $t("layout.navigation.dashboard")
              }}</q-item-label>
            </q-item-section>
          </div>
        </q-item> -->
        <q-item
          clickable
          tag="a"
          to="/trademarks"
          @click="showChildren = false"
        >
          <div class="row">
            <q-item-section avatar>
              <svg-trademarks></svg-trademarks>
            </q-item-section>
            <q-item-section>
              <q-item-label>{{
                $t("layout.navigation.applications")
              }}</q-item-label>
            </q-item-section>
          </div>
        </q-item>
        <q-item clickable tag="a" to="/renewals" @click="showChildren = false">
          <div class="row">
            <q-item-section avatar>
              <svg-renewal></svg-renewal>
            </q-item-section>
            <q-item-section>
              <q-item-label>{{
                $t("layout.navigation.renewals")
              }}</q-item-label>
            </q-item-section>
          </div>
        </q-item>
        <q-item clickable tag="a" to="/users" @click="showChildren = false">
          <div class="row">
            <q-item-section avatar>
              <svg-users></svg-users>
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ $t("layout.navigation.users") }}</q-item-label>
            </q-item-section>
          </div>
        </q-item>
        <q-item clickable tag="a" to="/pricing" @click="showChildren = false">
          <div class="row">
            <q-item-section avatar>
              <svg-pricing></svg-pricing>
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ $t("layout.navigation.pricing") }}</q-item-label>
            </q-item-section>
          </div>
        </q-item>
        <q-item clickable tag="a" to="/tags" @click="showChildren = false">
          <div class="row">
            <q-item-section avatar>
              <svg-tags></svg-tags>
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ $t("layout.navigation.tags") }}</q-item-label>
            </q-item-section>
          </div>
        </q-item>
        <q-item clickable tag="a" to="/coupons" @click="showChildren = false">
          <div class="row">
            <q-item-section avatar>
              <svg-coupon></svg-coupon>
            </q-item-section>
            <q-item-section>
              <q-item-label>{{ $t("layout.navigation.coupons") }}</q-item-label>
            </q-item-section>
          </div>
        </q-item>
      </q-list>
      <q-list class="drawer-signout">
        <q-item clickable tag="div" @click="logout()">
          <div class="row">
            <q-item-section avatar>
              <svg-logout></svg-logout>
            </q-item-section>
            <q-item-section>
              <q-item-label class="text-uppercase">{{
                $t("layout.navigation.signout")
              }}</q-item-label>
            </q-item-section>
          </div>
        </q-item>
      </q-list>
      <q-separator></q-separator>
    </q-drawer>

    <q-page-container>
      <router-view class="q-px-lg"></router-view>
    </q-page-container>
  </q-layout>
  <div v-else>
    <router-view></router-view>
  </div>
</template>

<script>
// import SvgDashboard from "@/components/svg/dashboard";
import SvgPricing from "@/components/svg/pricing";
import SvgTags from "@/components/svg/hashtag";
import SvgTrademarks from "@/components/svg/applications";
import SvgUsers from "@/components/svg/users";
import SvgCoupon from "@/components/svg/coupon";
import SvgLogout from "@/components/svg/logout";
import SvgProfile from "@/components/svg/profile";
import SvgSettings from "@/components/svg/settings";
import SvgTurnoff from "@/components/svg/turnoff";
import SvgRenewal from "@/components/svg/renewal";

export default {
  name: "LayoutDefault",
  components: {
    SvgPricing,
    SvgTags,
    SvgTrademarks,
    SvgUsers,
    SvgLogout,
    SvgRenewal,
    SvgTurnoff,
    SvgProfile,
    SvgSettings,
    SvgCoupon
  },
  data() {
    return {
      leftDrawerOpen: !this.$q.platform.is.mobile,
      showChildren: false,
      currentLang: ""
    };
  },
  methods: {
    logout() {
      this.showChildren = false;
      this.$store.commit("USER_LOGOUT");
      this.$router.push("/login");
    }
  }
};
</script>

<style></style>
