<template>
  <div class="login-page">
    <div class="background row">
      <div class="bg-left">
        <div class="bg-image"></div>
      </div>
      <div class="divider"></div>
      <div class="bg-right"></div>
    </div>
    <div class="overlay">
      <create-password-form></create-password-form>
    </div>
  </div>
</template>

<script>
import CreatePasswordForm from "@/components/CreatePasswordForm";
export default {
  components: {
    CreatePasswordForm
  },
  data() {
    return {
      forgetPassModal: false
    };
  }
};
</script>
