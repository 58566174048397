import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    locale: "en",
    locales: [
      {
        code: "en",
        name: "English"
      },
      {
        code: "ar",
        name: "Arabic"
      }
    ],
    isActivated: false,
    accessToken: null,
    user: null,
    role: null,
    apiURL: process.env.VUE_APP_API_URL
  },
  getters: {
    isAdmin(state) {
      return state.role === "Admin";
    },
    isEditor(state) {
      return state.role === "Editor";
    },
    isViewer(state) {
      return state.role === "Viewer";
    }
  },
  mutations: {
    SET_LANG(state, locale) {
      if (state.locales.find(el => el.code === locale)) {
        location.reload();
        state.locale = locale;
      }
    },
    USER_LOGIN(state, data) {
      state.accessToken = data.accessToken;
      state.user = data.user;
      const role =
        data.user.role.key[0].toUpperCase() + data.user.role.key.slice(1);
      state.role = role;
    },
    USER_ACTIVATION(state, status) {
      state.user.isActivated = status;
    },
    USER_LOGOUT(state) {
      state.accessToken = null;
      state.user = null;
      state.role = null;
    }
  },
  actions: {},
  modules: {},
  plugins: [createPersistedState()]
});
