<template>
  <div class="trademarks">
    <div class="q-pa-lg-md q-py-lg">
      <q-table
        title="Applications"
        :filter="filter"
        :data="data"
        :columns="columns"
        row-key="name"
        :loading="loading"
        class="users-table"
        :pagination.sync="initialPagination"
        @request="getData"
      >
        <template v-slot:loading>
          <q-inner-loading showing color="primary" />
        </template>
        <template v-slot:top-right>
          <q-input
            borderless
            dense
            filled
            debounce="300"
            v-model="filter"
            class="q-pa-lg-none q-py-lg"
            placeholder="Search"
          >
            <template v-slot:append>
              <q-icon name="search" />
            </template>
          </q-input>
          <!-- <q-btn
            class="q-ml-md \"
            to="/trademarks"
            color="primary"
            label="Add New Trademark"
            no-caps
          /> -->
        </template>
        <template v-slot:body-cell-image="props">
          <q-td :props="props">
            <div class="row">
              <img
                v-if="props.row.image"
                class="trademark-img"
                :src="baseURL + `${props.row.image.thumbnail}`"
                alt="Trademark"
              />
              <span v-else></span>
            </div>
          </q-td>
        </template>

        <!-- https://uat.search.ipaustralia.gov.au/trademarks/search/view/2198696 -->
        <template v-slot:body-cell-appNumber="props">
          <q-td :props="props">
            <a
              :href="`https://uat.search.ipaustralia.gov.au/trademarks/search/view/${props.row.appNumber}`"
              target="_blank"
              v-if="props.row.country.code == 'AU'"
              >{{ props.row.appNumber }}</a
            >
            <span v-else>{{ props.row.appNumber }}</span>
          </q-td>
        </template>

        <template v-slot:body-cell-word="props">
          <q-td :props="props">
            <span>{{ props.row.word ? props.row.word : null }}</span>
          </q-td>
        </template>
        <template v-slot:body-cell-user="props">
          <q-td :props="props">
            <div v-if="props.row.user">
              <p v-if="props.row.user.profile.type === 'person'">
                {{ props.row.user.profile.firstName }}
                {{ props.row.user.profile.lastName }}
              </p>
              <p v-if="props.row.user.profile.type === 'business'">
                {{ props.row.user.profile.ownerName }}
              </p>
              <span>{{ props.row.user.email }}</span>
            </div>
          </q-td>
        </template>
        <template v-slot:body-cell-actions="props">
          <q-td :props="props">
            <div class="actions">
              <q-btn
                dense
                round
                flat
                color="grey"
                class="edit-button"
                icon="visibility"
                :to="`/trademarks/${props.row.id}`"
              ></q-btn>
              <q-btn
                class="delete-button"
                dense
                round
                flat
                color="red"
                @click="deleteRow(props.row.id)"
                icon="delete"
              ></q-btn>
            </div>
          </q-td>
        </template>
      </q-table>
    </div>
    <q-dialog
      v-model="deletePointsModalVisible"
      transition-show="flip-down"
      transition-hide="flip-up"
    >
      <q-card>
        <q-bar>
          <h3 class="text-white text-uppercase">Confirm the action</h3>
          <q-space />
          <q-btn dense flat icon="close" color="white" v-close-popup> </q-btn>
        </q-bar>
        <q-card-section>
          <q-btn
            class="float-right q-mt-lg q-ml-md"
            :label="$t('cancel')"
            v-close-popup
            outline
            color="primary"
          />
          <q-btn
            class="float-right q-mt-lg"
            color="primary"
            :label="$t('delete')"
            @click="deleteTrademark()"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import moment from "moment";

export default {
  data() {
    return {
      initialPagination: {
        page: 1,
        rowsNumber: 0,
        total: 0,
        rowsPerPage: 10,
        pageCount: 2,
      },
      trademarkID: null,
      deletePointsModalVisible: false,
      loading: false,
      filter: "",
      columns: [
        {
          name: "id",
          required: true,
          label: "TM ID",
          align: "left",
          field: "invoiceNumber",
        },
        {
          name: "appNumber",
          required: true,
          label: "Application no.",
          align: "left",
          field: "appNumber",
        },
        {
          name: "word",
          align: "center",
          label: "Word",
          field: (row) => row.word,
        },
        {
          name: "image",
          required: true,
          label: "Image",
          align: "left",
          field: (row) => (row.image ? row.image : null),
        },
        {
          name: "country",
          label: "Country",
          field: (res) => res.country.code,
        },
        {
          name: "payment",
          label: "Payment Info",
          field: (res) =>
            res.payment && res.payment.invoice
              ? res.payment.invoice.amount
              : null,
        },
        {
          name: "user",
          label: "User Details",
          field: (row) => row.user.email,
        },
        {
          name: "createdAt",
          label: "Date",
          field: (row) => moment(row.createdAt).format("YYYY-MM-DD HH:mm"),
        },
        { name: "actions", label: "Actions", field: "actions" },
      ],
      data: [],
      baseURL: process.env.VUE_APP_API_URL,
    };
  },
  created() {
    this.getData();
  },
  methods: {
    getData(props) {
      this.loading = true;
      const qb = RequestQueryBuilder.create();
      let page, rowsPerPage, pageCount;
      const pg = props ? props.pagination : {};
      page = pg.page || this.initialPagination.page;
      rowsPerPage =
        pg.rowsPerPage != undefined
          ? pg.rowsPerPage
          : this.initialPagination.rowsPerPage;
      pageCount = pg.pageCount || this.initialPagination.pageCount;
      this.initialPagination.page = page;
      this.initialPagination.rowsPerPage = rowsPerPage;
      this.initialPagination.pageCount = pageCount;
      if (page) {
        qb.setPage(page);
      }

      if (rowsPerPage) {
        qb.setLimit(rowsPerPage);
      }

      if (this.filter) {
        qb.setOr([
          { field: "word", operator: "$cont", value: this.filter },
          { field: "appNumber", operator: "$cont", value: this.filter },
          { field: "user.email", operator: "$cont", value: this.filter },
          {
            field: "user.profile.firstName",
            operator: "$cont",
            value: this.filter,
          },
          {
            field: "user.profile.lastName",
            operator: "$cont",
            value: this.filter,
          },
        ]);
      }
      qb.sortBy({ field: "id", order: "DESC" });

      this.$http.trademarks.get(qb).then((res) => {
        this.data = res.data.data || res.data;
        this.initialPagination.pageCount = res.data.pageCount;
        this.initialPagination.page = res.data.page;
        this.initialPagination.rowsNumber = res.data.total;
        this.loading = false;
      });
    },
    deleteRow(id) {
      this.deletePointsModalVisible = true;
      this.trademarkID = id;
    },
    async deleteTrademark() {
      this.deletePointsModalVisible = false;
      const index = this.data.findIndex(
        (trademark) => trademark.id === this.trademarkID
      );
      this.data.splice(index, 1);
      try {
        // await this.$http.trademarks.delete(this.tagID);
        this.$q.notify({
          color: "positive",
          icon: "done",
          position: "top",
          message: "Trademark Deleted Successfully",
          timeout: 1000,
        });
      } catch (err) {
        this.$q.notify({
          color: "negative",
          icon: "warning",
          position: "top",
          message: err.response.data.message,
          timeout: 1000,
        });
      }
    },
  },
};
</script>
