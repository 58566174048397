<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.316"
    height="17.316"
    viewBox="0 0 17.316 17.316"
  >
    <g transform="translate(0.65 0.65)">
      <circle
        class="a"
        cx="2"
        cy="2"
        r="2"
        transform="translate(6.206 6.016)"
      />
      <path
        class="a"
        d="M13.395,10.192a1.2,1.2,0,0,0,.24,1.325l.044.044a1.457,1.457,0,1,1-2.06,2.06l-.044-.044a1.211,1.211,0,0,0-2.053.859v.124a1.456,1.456,0,1,1-2.912,0v-.066a1.2,1.2,0,0,0-.786-1.1,1.2,1.2,0,0,0-1.325.24l-.044.044a1.457,1.457,0,1,1-2.06-2.06l.044-.044A1.211,1.211,0,0,0,1.58,9.522H1.456a1.456,1.456,0,1,1,0-2.912h.066a1.2,1.2,0,0,0,1.1-.786A1.2,1.2,0,0,0,2.381,4.5l-.044-.044A1.457,1.457,0,1,1,4.4,2.4l.044.044a1.2,1.2,0,0,0,1.325.24h.058a1.2,1.2,0,0,0,.728-1.1V1.456a1.456,1.456,0,0,1,2.912,0v.066a1.211,1.211,0,0,0,2.053.859l.044-.044a1.457,1.457,0,1,1,2.06,2.06l-.044.044a1.2,1.2,0,0,0-.24,1.325v.058a1.2,1.2,0,0,0,1.1.728h.124a1.456,1.456,0,1,1,0,2.912h-.066A1.2,1.2,0,0,0,13.395,10.192Z"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
.a {
  fill: none;
  stroke: #1a2d4c;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 10;
  stroke-width: 1.3px;
}
</style>
