<template>
  <div class="coupons" v-if="loadPage">
    <template>
      <div class="q-pa-md">
        <q-table
          title="Coupons"
          :filter="filter"
          :data="data"
          :columns="columns"
          :loading="loading"
          row-key="name"
          class="coupons-table"
          :pagination.sync="initialPagination"
          @request="getCoupons"
        >
          <template v-slot:loading>
            <q-inner-loading showing color="primary" />
          </template>
          <template v-slot:top-right>
            <q-input
              borderless
              dense
              filled
              debounce="300"
              v-model="filter"
              placeholder="Search"
            >
              <template v-slot:append>
                <q-icon name="search" />
              </template>
            </q-input>
            <q-btn
              class="q-ml-md add-coupon"
              to="/coupons/add-coupon"
              color="primary"
              label="Add New Coupon"
              no-caps
            />
          </template>
          <template v-slot:body-cell-actions="props">
            <q-td :props="props">
              <div class="actions">
                <q-btn
                  dense
                  round
                  flat
                  color="grey"
                  class="edit-button"
                  icon="edit"
                  :to="`/coupons/${props.row.id}`"
                ></q-btn>
                <q-btn
                  class="assign-button"
                  dense
                  round
                  flat
                  color="grey"
                  @click="openUsersModal(props.row)"
                  icon="assignment_turned_in"
                  v-if="props.row.type == 'multiple'"
                ></q-btn>
                <q-btn
                  class="delete-button"
                  dense
                  round
                  flat
                  color="red"
                  @click="deleteRow(props.row.id)"
                  icon="delete"
                ></q-btn>
              </div>
            </q-td>
          </template>
        </q-table>
      </div>
    </template>
    <q-dialog
      v-model="deletePointsModalVisible"
      transition-show="flip-down"
      transition-hide="flip-up"
    >
      <q-card>
        <q-bar>
          <h3 class="text-white text-uppercase">Confirm the action</h3>
          <q-space />
          <q-btn dense flat icon="close" color="white" v-close-popup> </q-btn>
        </q-bar>
        <q-card-section>
          <q-btn
            class="float-right q-mt-lg q-ml-md"
            :label="$t('cancel')"
            v-close-popup
            outline
            color="primary"
          />
          <q-btn
            class="float-right q-mt-lg"
            color="primary"
            :label="$t('delete')"
            @click="deleteUser()"
          />
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog
      v-model="usersModalVisible"
      transition-show="flip-down"
      transition-hide="flip-up"
    >
      <template>
        <div>
          <q-table
            title="Users"
            :data="users"
            :columns="userColumns"
            :loading="visible"
            row-key="id"
            :selected.sync="selected"
            :selected-rows-label="getSelectedString"
            selection="multiple"
            v-model="selected"
            :pagination.sync="usersPagination"
            @request="getUsers"
          >
            <template v-slot:loading>
              <q-inner-loading showing color="primary" />
            </template>
            <template v-slot:top-right>
              <q-btn
                class="assign-coupon"
                color="primary"
                @click="assignToUser()"
                label="Save"
              />
            </template>
          </q-table>
        </div>
      </template>
    </q-dialog>
  </div>
</template>

<script>
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import moment from "moment";
export default {
  data() {
    return {
      assignedUsers: null,
      coupon: {},
      loading: false,
      selected: [],
      userColumns: [
        {
          name: "firstName",
          required: true,
          label: "Name",
          align: "left",
          field: (row) =>
            row.profile.type === "person"
              ? row.profile.firstName + " " + row.profile.lastName
              : row.profile.ownerName,
          sortable: true,
        },
      ],
      users: [],
      filter: "",
      loadPage: true,
      couponID: null,
      deletePointsModalVisible: false,
      usersModalVisible: false,
      visible: false,
      columns: [
        {
          name: "type",
          align: "center",
          label: "Type",
          field: (row) => `${row.type} ${row.isUsed ? "(used)" : ""}`,
          sortable: true,
        },
        {
          name: "title",
          required: true,
          label: "Title",
          align: "left",
          field: "title",
          sortable: true,
        },
        {
          name: "expiration_date",
          required: true,
          field: (row) => moment(row.expiration_date).format("DD-MM-YYYY"),
          label: "Date",
          align: "left",
          sortable: true,
        },
        {
          name: "discount",
          label: "Discount Percent",
          field: (row) => row.discount + "%",
          sortable: true,
        },
        {
          name: "code",
          label: "Discount Code",
          field: (row) => row.code,
        },
        { name: "status", label: "Status", field: (row) => row.status },
        { name: "actions", label: "Actions", field: "actions" },
      ],
      usersPagination: {
        page: 1,
        rowsNumber: 0,
        total: 0,
        rowsPerPage: 5,
        pageCount: 2,
      },
      initialPagination: {
        page: 1,
        rowsNumber: 0,
        total: 0,
        rowsPerPage: 10,
        pageCount: 2,
      },
      data: [],
      user: {},
      userID: null,
      userIdData: [],
    };
  },
  mounted() {
    this.getCoupons();
  },
  methods: {
    getCoupons(props) {
      this.loading = true;
      const qb = RequestQueryBuilder.create();
      let page, rowsPerPage, pageCount;
      const pg = props ? props.pagination : {};
      page = pg.page || this.initialPagination.page;
      rowsPerPage =
        pg.rowsPerPage != undefined
          ? pg.rowsPerPage
          : this.initialPagination.rowsPerPage;
      pageCount = pg.pageCount || this.initialPagination.pageCount;
      this.initialPagination.page = page;
      this.initialPagination.rowsPerPage = rowsPerPage;
      this.initialPagination.pageCount = pageCount;
      if (page) {
        qb.setPage(page);
      }
      if (rowsPerPage) {
        qb.setLimit(rowsPerPage);
      }
      qb.sortBy({ field: "id", order: "DESC" });

      this.$http.coupons.get(qb).then((res) => {
        this.data = res.data.data || res.data;
        this.initialPagination.pageCount = res.data.pageCount;
        this.initialPagination.page = res.data.page;
        this.initialPagination.rowsNumber = res.data.total;
        this.loading = false;
      });
    },
    getSelectedString() {
      const data = this.selected.filter(
        (user) => !this.assignedUsers.includes(user)
      );
      return data.map((i) => i.id);
    },
    getUsersToUnassign() {
      const data = this.assignedUsers.filter(
        (user) => !this.selected.includes(user)
      );
      return data.map((i) => i.id);
    },
    deleteRow(id) {
      this.couponID = id;
      this.deletePointsModalVisible = true;
    },
    async deleteUser() {
      this.deletePointsModalVisible = false;
      const index = this.data.findIndex(
        (coupon) => coupon.id === this.couponID
      );
      this.data.splice(index, 1);
      try {
        await this.$http.coupons.delete(this.couponID);
        this.$q.notify({
          color: "positive",
          icon: "done",
          position: "top",
          message: "Coupon Deleted Successfully",
          timeout: 1000,
        });
      } catch (err) {
        this.$q.notify({
          color: "negative",
          icon: "warning",
          position: "top",
          message: "Something went wrong",
          timeout: 1000,
        });
      }
    },
    getUsers(props) {
      this.visible = true;
      const query = RequestQueryBuilder.create();
      let page, rowsPerPage, pageCount;
      const pg = props ? props.pagination : {};
      page = pg.page || this.usersPagination.page;
      rowsPerPage = pg.rowsPerPage || this.usersPagination.rowsPerPage;
      pageCount = pg.pageCount || this.usersPagination.pageCount;
      this.usersPagination.page = page;
      this.usersPagination.rowsPerPage = rowsPerPage;
      this.usersPagination.pageCount = pageCount;
      if (page) {
        query.setPage(page);
      }
      if (rowsPerPage) {
        query.setLimit(rowsPerPage);
      }
      this.$http.users.get(query).then((res) => {
        this.users = res.data.data;
        this.visible = false;
        this.usersPagination.pageCount = res.data.pageCount;
        this.usersPagination.page = res.data.page;
        this.usersPagination.rowsNumber = res.data.total;
      });
    },
    openUsersModal(coupon) {
      this.visible = true;
      this.assignedUsers = coupon.assignedUsers;
      this.selected = coupon.assignedUsers;
      this.getUsers();
      this.coupon = coupon;
      this.couponID = coupon.id;
      this.usersModalVisible = true;
    },
    async deleteAssignedUsers() {
      const users = this.getUsersToUnassign();
      const data = {
        userIds: users,
        couponId: this.coupon.id,
      };
      this.$http.coupons.unassignUser(data);
    },
    async assignToUser() {
      this.userIdData = this.getSelectedString();
      const data = {
        userIds: this.userIdData,
        couponId: this.coupon.id,
      };
      if (this.userIdData.length > 0) {
        this.$http.coupons
          .assignToUser(data)
          .then(() => {
            this.$q.notify({
              color: "positive",
              icon: "done",
              position: "top",
              message: "Coupons Assignment Changed Successfully",
              timeout: 1000,
            });
            this.deleteAssignedUsers();
            this.usersModalVisible = false;
            this.userIdData = [];
            this.getCoupons();
          })
          .catch((res) => {
            this.$q.notify({
              color: "negative",
              icon: "warning",
              position: "top",
              message: res.data.message,
              timeout: 1000,
            });
          });
      } else {
        this.$q.notify({
          color: "negative",
          icon: "warning",
          position: "top",
          message: "Please select coupons",
          timeout: 1000,
        });
      }

      this.visible = false;
    },
  },
};
</script>
