<template>
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    style="enable-background:new 0 0 512 512;"
    xml:space="preserve"
  >
    <g>
      <g>
        <path
          d="M495.304,72.348H16.696C7.475,72.348,0,79.823,0,89.044v333.913c0,9.22,7.475,16.696,16.696,16.696h478.609
			c9.22,0,16.696-7.475,16.696-16.696V89.044C512,79.823,504.525,72.348,495.304,72.348z M478.609,406.261H178.087v-16.696
			c0-9.22-7.475-16.696-16.696-16.696s-16.696,7.475-16.696,16.696v16.696H33.391V105.739h111.304v16.696
			c0,9.22,7.475,16.696,16.696,16.696s16.696-7.475,16.696-16.696v-16.696h300.522V406.261z"
          fill="#8181a5"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M161.391,266.017c-9.22,0-16.696,7.475-16.696,16.696v53.426c0,9.22,7.475,16.696,16.696,16.696
			s16.696-7.475,16.696-16.696v-53.426C178.087,273.493,170.612,266.017,161.391,266.017z"
          fill="#8181a5"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M161.391,159.165c-9.22,0-16.696,7.475-16.696,16.696v53.426c0,9.22,7.475,16.696,16.696,16.696
			s16.696-7.475,16.696-16.696v-53.426C178.087,166.64,170.612,159.165,161.391,159.165z"
          fill="#8181a5"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M261.565,139.13c-27.618,0-50.087,22.469-50.087,50.087c0,27.618,22.469,50.087,50.087,50.087
			c27.618,0,50.087-22.469,50.087-50.087C311.652,161.599,289.183,139.13,261.565,139.13z M261.565,205.913
			c-9.206,0-16.696-7.49-16.696-16.696s7.49-16.696,16.696-16.696c9.206,0,16.696,7.49,16.696,16.696
			S270.771,205.913,261.565,205.913z"
          fill="#8181a5"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M395.13,272.696c-27.618,0-50.087,22.47-50.087,50.087c0,27.618,22.469,50.087,50.087,50.087s50.087-22.469,50.087-50.087
			S422.748,272.696,395.13,272.696z M395.13,339.478c-9.206,0-16.696-7.49-16.696-16.696c0-9.206,7.49-16.696,16.696-16.696
			s16.696,7.49,16.696,16.696C411.826,331.989,404.336,339.478,395.13,339.478z"
          fill="#8181a5"
        />
      </g>
    </g>
    <g>
      <g>
        <path
          d="M410.981,173.371c-6.519-6.52-17.091-6.52-23.611,0L245.704,315.038c-6.519,6.52-6.519,17.091,0,23.611
			c3.26,3.26,7.533,4.891,11.805,4.891c4.272,0,8.546-1.63,11.805-4.891l141.667-141.667
			C417.501,190.462,417.501,179.891,410.981,173.371z"
          fill="#8181a5"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
</template>

<script>
export default {};
</script>
