<template>
  <div class="users">
    <template>
      <div class="q-pa-md">
        <q-table
          title="Renewals"
          :filter="filter"
          :data="data"
          :columns="columns"
          :loading="loading"
          row-key="id"
          class="users-table"
          :pagination.sync="initialPagination"
          @request="getData"
        >
          <template v-slot:loading>
            <q-inner-loading showing color="primary" />
          </template>
          <template v-slot:body-cell-rightsNumber="props">
            <td :props="props">
              <p v-for="(item, i) in props.row.renewalItems" :key="i">
                {{ item.registrationNumber }}
              </p>
            </td>
          </template>
          <template v-slot:body-cell-renewalDueDate="props">
            <td :props="props">
              <p v-for="(item, i) in props.row.renewalItems" :key="i">
                {{ formatDate(item.newRenewalDueToDate) }}
              </p>
            </td>
          </template>
          <template v-slot:body-cell-classes="props">
            <td :props="props">
              <p v-for="(item, i) in props.row.classes" :key="i">
                {{ getClasses(item.classes) }}
              </p>
            </td>
          </template>
        </q-table>
      </div>
    </template>
  </div>
</template>

<script>
import { RequestQueryBuilder } from "@nestjsx/crud-request";
import moment from "moment";
export default {
  data() {
    return {
      filter: "",
      loading: false,
      columns: [
        {
          name: "owner",
          required: true,
          label: "Owner",
          align: "left",
          field: (row) => {
            if (row.user.profile.type == "business") {
              return row.user.profile.ownerName;
            } else {
              return (
                row.user.profile.firstName + " " + row.user.profile.lastName
              );
            }
          },
        },
        {
          name: "country",
          required: true,
          label: "Country",
          align: "left",
          field: (row) => row.country.name,
        },
        {
          name: "trademark",
          align: "center",
          label: "Trademark",
          field: "trademark",
        },
        {
          name: "rightsNumber",
          required: true,
          label: "Rights number",
          align: "left",
        },
        { name: "classes", label: "Classes", align: "left" },
        {
          name: "renewalDueDate",
          label: "Renewal Due Date",
          required: true,
          align: "left",
        },
        {
          name: "renewalCost",
          label: "Renewal Cost",
          field: (row) =>
            row.payment.invoice.amount
              ? "$" + row.payment.invoice.amount / 100
              : null,
        },
      ],
      initialPagination: {
        page: 1,
        rowsNumber: 0,
        total: 0,
        rowsPerPage: 10,
        pageCount: 2,
      },
      data: [],
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    formatDate(val) {
      return moment(val).format("DD/MM/YYYY");
    },
    getClasses(array) {
      return array.map((el) => el.id).join(",");
    },
    getData(props) {
      this.loading = true;
      const qb = RequestQueryBuilder.create();
      let page, rowsPerPage, pageCount;
      const pg = props ? props.pagination : {};
      page = pg.page || this.initialPagination.page;
      rowsPerPage =
        pg.rowsPerPage != undefined
          ? pg.rowsPerPage
          : this.initialPagination.rowsPerPage;
      pageCount = pg.pageCount || this.initialPagination.pageCount;
      this.initialPagination.page = page;
      this.initialPagination.rowsPerPage = rowsPerPage;
      this.initialPagination.pageCount = pageCount;
      if (page) {
        qb.setPage(page);
      }
      if (rowsPerPage) {
        qb.setLimit(rowsPerPage);
      }
      qb.sortBy({ field: "id", order: "DESC" });

      this.$http.renewals.get(qb).then((res) => {
        this.data = res.data.data || res.data;
        this.initialPagination.pageCount = res.data.pageCount;
        this.initialPagination.page = res.data.page;
        this.initialPagination.rowsNumber = res.data.total;
        this.loading = false;
      });
    },
  },
};
</script>
