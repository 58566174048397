const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
const phonePattern = /^[0-9]+$/;
export const email = val =>
  emailPattern.test(val) || "Please enter a valid email address";
export const phone = val =>
  phonePattern.test(val) || "Please enter a valid phone number";
export const required = val => !!val || "Field is required";
export const requiredByType = function(type) {
  return val =>
    !!val || this.$refs.userTypeRef.value != type || "Field is required";
};
export const requiredByPostalAddress = function(val) {
  return !!val || this.$refs.postalAddress.value || "Field is required";
};
export const ConfirmPWD = function() {
  const disableValidation = !this.$refs.fldPasswordChangeConfirm.value;

  if (disableValidation) return true;

  const validity =
    this.$refs.fldPasswordChangeConfirm.value ===
      this.$refs.fldPasswordChange.value || "Different passwords";

  if (validity) {
    this.$refs.fldPasswordChange.resetValidation();
    this.$refs.fldPasswordChangeConfirm.resetValidation();
  }

  return validity;
};
