<template>
  <div class="pos-relative ">
    <breadcrumb current="Add New Coupon"></breadcrumb>
    <template>
      <div class="add-user-form q-pa-md q-mt-md">
        <template>
          <div class="q-pt-md">
            <div class="q-gutter-md">
              <q-input
                :rules="[required]"
                filled
                v-model="coupon.title"
                label="Title"
                ref="title"
                class="q-py-sm"
              />
            </div>
          </div>
          <div class="q-pt-md">
            <div class="q-gutter-md">
              <q-input
                :rules="[required]"
                filled
                v-model="coupon.discount"
                label="Discount Percent"
                ref="discount"
                class="q-py-sm"
              >
                <template v-slot:append>
                  %
                </template>
              </q-input>
            </div>
          </div>
          <div class="q-pt-md">
            <div class="q-gutter-md">
              <q-select
                filled
                :rules="[required]"
                v-model="coupon.type"
                :options="type_options"
                label="Type"
                @input="changeTypeValue"
                ref="couponType"
                class="q-py-sm"
              />
            </div>
          </div>
          <div class="q-pt-md">
            <div class="q-gutter-md">
              <q-input
                filled
                v-model="coupon.expiration_date"
                mask="date"
                ref="expirationDate"
                :rules="[required, 'date']"
                label="Expiration Date"
                class="q-py-sm"
              >
                <template v-slot:append>
                  <q-icon name="event" class="cursor-pointer">
                    <q-popup-proxy
                      ref="qDateProxy"
                      transition-show="scale"
                      transition-hide="scale"
                    >
                      <q-date v-model="coupon.expiration_date">
                        <div class="row items-center justify-end">
                          <q-btn
                            v-close-popup
                            label="Close"
                            color="primary"
                            flat
                          />
                        </div>
                      </q-date>
                    </q-popup-proxy>
                  </q-icon>
                </template>
              </q-input>
            </div>
          </div>
          <div class="q-pt-md add-user-btn">
            <q-btn
              class="add-user q-mt-sm"
              color="primary"
              label="Add New Coupon"
              no-caps
              @click="createCoupon()"
            />
          </div>
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import { required } from "../utils/validations";

export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      coupon: {
        type: "",
        title: "",
        expiration_date: "",
        discount: ""
      },
      type_options: [
        {
          label: "multiple",
          value: "multiple"
        },
        {
          label: "unitary",
          value: "unitary"
        }
      ]
    };
  },
  created() {},
  methods: {
    required,
    isValid() {
      const validationArray = [
        this.$refs.title.validate(),
        this.$refs.couponType.validate(),
        this.$refs.discount.validate(),
        this.$refs.expirationDate.validate()
      ];

      return validationArray.every(v => v === true);
    },
    async createCoupon() {
      if (this.isValid()) {
        try {
          await this.$http.coupons.create(this.coupon);
          this.$q.notify({
            color: "positive",
            icon: "done",
            position: "top",
            message: "Coupon Successfully Created",
            timeout: 1000
          });
          await this.$router.push("/coupons");
        } catch (err) {
          this.$q.notify({
            color: "negative",
            icon: "warning",
            position: "top",
            message: "Invalid Credentials",
            timeout: 1000
          });
        }
      }
    },

    changeTypeValue(val) {
      this.coupon.type = val.value;
      this.type = val.label;
    }
  }
};
</script>
