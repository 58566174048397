<template>
  <div class="pos-relative ">
    <breadcrumb current="Add New User"></breadcrumb>
    <div class="add-user-form q-pa-md q-mt-md">
      <template>
        <div class="q-pt-sm">
          <div class="q-gutter-md">
            <q-select
              filled
              v-model="type"
              :options="type_options"
              label="Type"
              @input="changeTypeValue"
              ref="userTypeRef"
              :rules="[required]"
            />
          </div>
        </div>

        <div class="q-pt-sm">
          <div class="q-gutter-md">
            <q-select
              filled
              v-model="user.profile.address.country"
              :options="countries"
              option-label="name"
              ref="country"
              label="Country"
              :rules="[required]"
              @input="selectCountry"
            />
          </div>
        </div>
        <div
          class="q-pt-sm "
          v-if="user.profile.address.country && regions.length > 0"
        >
          <div class="q-gutter-md">
            <q-select
              filled
              class="region"
              v-model="user.profile.address.region"
              :options="regions"
              option-label="name"
              ref="region"
              label="Region"
              @input="selectRegion"
            />
          </div>
        </div>
        <div class="q-pt-sm" v-if="user.profile.type === 'person'">
          <div class="q-gutter-md">
            <q-select
              filled
              v-model="title"
              :options="titles"
              label="Title"
              ref="title"
              @input="changeTitle"
              :rules="[requiredByTypeRule('Person')]"
            />
          </div>
        </div>
        <div class="q-pt-sm" v-if="user.profile.type === 'person'">
          <div class="q-gutter-md">
            <q-input
              filled
              v-model="user.profile.firstName"
              label="Firstname"
              ref="firstName"
              :rules="[requiredByTypeRule('Person')]"
            />
          </div>
        </div>
        <div class="q-pt-sm" v-if="user.profile.type === 'person'">
          <div class="q-gutter-md">
            <q-input
              filled
              v-model="user.profile.lastName"
              label="Lastname"
              :rules="[requiredByTypeRule('Person')]"
              ref="lastName"
            />
          </div>
        </div>
        <div class="q-pt-sm" v-if="user.profile.type === 'business'">
          <div class="q-gutter-md">
            <q-input
              filled
              v-model="user.profile.ownerName"
              label="Ownername"
              ref="ownerName"
              :rules="[requiredByTypeRule('Business')]"
            />
          </div>
        </div>
        <div class="q-pt-sm">
          <div class="q-gutter-md">
            <q-input
              filled
              v-model="user.email"
              type="email"
              label="Contact Email"
              :rules="[email, required]"
              ref="email"
            >
              <template v-slot:before>
                <q-icon name="mail" />
              </template>
            </q-input>
          </div>
        </div>
        <div class="q-pt-sm">
          <div class="q-gutter-md">
            <q-input
              filled
              v-model="user.password"
              :type="isPwd ? 'password' : 'text'"
              label="Password"
              ref="fldPasswordChange"
              :rules="[required, confirmPass]"
            >
              <template v-slot:append>
                <q-icon
                  :name="isPwd ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  v-on:click="isPwd = !isPwd"
                ></q-icon>
              </template>
            </q-input>
          </div>
        </div>
        <div class="q-pt-sm">
          <div class="q-gutter-md">
            <q-input
              filled
              v-model="confirm_password"
              :type="isConfirmPwd ? 'password' : 'text'"
              label="Password Confirmation"
              ref="fldPasswordChangeConfirm"
              :rules="[required, confirmPass]"
            >
              <template v-slot:append>
                <q-icon
                  :name="isConfirmPwd ? 'visibility_off' : 'visibility'"
                  class="cursor-pointer"
                  v-on:click="isConfirmPwd = !isConfirmPwd"
                ></q-icon>
              </template>
            </q-input>
          </div>
        </div>
        <div class="q-pt-sm">
          <div class="q-gutter-md">
            <q-input
              filled
              v-model="user.profile.phone"
              type="tel"
              label="Phone"
              ref="phone"
              :rules="[required, phone]"
            />
          </div>
        </div>
        <div class="q-pt-sm">
          <div class="q-gutter-md">
            <q-input
              filled
              v-model="user.profile.address.address"
              label="Address"
              ref="address"
              :rules="[required]"
            />
          </div>
        </div>
        <div class="q-pt-sm">
          <div class="q-gutter-md">
            <q-input
              filled
              v-model="user.profile.address.postCode"
              label="Post Code"
              ref="postCode"
              :rules="[required]"
            />
          </div>
        </div>
        <div class="q-pt-sm">
          <div class="q-gutter-md">
            <q-input
              filled
              v-model="user.profile.address.city"
              label="Town/City"
              ref="city"
              :rules="[required]"
            />
          </div>
        </div>
        <div class="q-pt-sm">
          <div class="q-gutter-md">
            <q-toggle
              v-model="user.profile.isPostalAddressSame"
              label="Postal address same as above?"
              ref="postalAddress"
            />
          </div>
        </div>
        <div v-if="!user.profile.isPostalAddressSame">
          <div class="q-pt-sm">
            <div class="q-gutter-md">
              <q-input
                filled
                v-model="user.profile.postalAddress.address"
                label="Postal Address"
                :rules="[requiredByPostalAddressRule]"
              />
            </div>
          </div>
          <div class="q-pt-sm">
            <div class="q-gutter-md">
              <q-input
                filled
                v-model="user.profile.postalAddress.postCode"
                label="Postal Post Code"
                :rules="[requiredByPostalAddressRule]"
              />
            </div>
          </div>
          <div class="q-pt-sm">
            <div class="q-gutter-md">
              <q-input
                filled
                v-model="user.profile.postalAddress.city"
                label="Postal Town/City"
                :rules="[requiredByPostalAddressRule]"
              />
            </div>
          </div>
          <div class="q-pt-sm">
            <div class="q-gutter-md">
              <q-select
                filled
                v-model="user.profile.postalAddress.country"
                :options="countries"
                option-label="name"
                label="Postal Country"
                :rules="[requiredByPostalAddressRule]"
              />
            </div>
          </div>
        </div>
        <div class="q-pt-sm add-user-btn">
          <q-btn
            class="add-user"
            color="primary"
            label="Add New User"
            no-caps
            @click="createUser()"
          />
        </div>
      </template>
    </div>
    <q-dialog
      v-model="showSuggestions"
      transition-show="flip-down"
      transition-hide="flip-up"
    >
      <q-card>
        <q-bar>
          <h3 class="text-white text-uppercase">
            Suggestions
          </h3>
          <q-space />
          <q-btn dense flat icon="close" color="white" v-close-popup> </q-btn>
        </q-bar>
        <q-card-section>
          <q-table
            :data="suggestions"
            :columns="suggestion_columns"
            row-key="id"
            class="users-table"
            @row-click="rowclick"
          >
          </q-table>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import Breadcrumb from "@/components/Breadcrumb";
import {
  phone,
  email,
  required,
  ConfirmPWD,
  requiredByType,
  requiredByPostalAddress
} from "../utils/validations";

export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      showSuggestions: false,
      suggestions: [],
      isPwd: true,
      isConfirmPwd: true,
      confirmPass: () => {},
      requiredByTypeRule: () => {},
      requiredByPostalAddressRule: () => {},
      userId: null,
      confirm_password: null,
      user: {
        email: "",
        password: "",
        role: "client",
        profile: {
          title: "",
          type: "business",
          firstName: "",
          lastName: "",
          ownerName: "",
          phone: "",
          isPostalAddressSame: true,
          abn: "",
          address: {
            address: "",
            postCode: "",
            countryId: null,
            regionId: null,
            city: ""
          },
          postalAddress: {
            address: "",
            postCode: "",
            countryId: null,
            regionId: null,
            city: ""
          }
        }
      },
      suggestion_columns: [
        {
          name: "city",
          align: "center",
          label: "City",
          field: row => row.address.locality,
          sortable: true
        },
        {
          name: "country",
          align: "center",
          label: "Country",
          field: row => row.address.country,
          sortable: true
        },
        {
          name: "address",
          label: "Address",
          field: row => row.address.formattedAddress.join()
        },
        {
          name: "postCode",
          align: "center",
          label: "Postcode",
          field: row => row.address.postalCode,
          sortable: true
        },
        {
          name: "Action"
        }
      ],
      countries: [],
      title: "",
      titles: [
        {
          label: "MR",
          value: "MR"
        },
        {
          label: "MS",
          value: "MS"
        },
        {
          label: "MRS",
          value: "MRS"
        },
        {
          label: "MISS",
          value: "MISS"
        },
        {
          label: "MX",
          value: "MX"
        },
        {
          label: "MASTER",
          value: "MASTER"
        }
      ],
      type: "Business",
      type_options: [
        {
          label: "Business",
          value: "business"
        },
        {
          label: "Person",
          value: "person"
        }
      ],
      regions: []
    };
  },
  created() {
    this.confirmPass = ConfirmPWD.bind(this);
    this.requiredByTypeRule = requiredByType.bind(this);
    this.requiredByPostalAddressRule = requiredByPostalAddress.bind(this);
    this.$http.users.getCountries().then(res => {
      this.countries = res.data;
    });
  },
  methods: {
    email,
    required,
    phone,
    isValid() {
      const validationArray = [
        this.$refs.userTypeRef.validate(),
        this.$refs.country.validate(),
        this.$refs.email.validate(),
        this.$refs.fldPasswordChange.validate(),
        this.$refs.fldPasswordChangeConfirm.validate(),
        this.$refs.phone.validate(),
        this.$refs.address.validate(),
        this.$refs.postCode.validate(),
        this.$refs.city.validate()
      ];
      if (this.user.profile.type === "person") {
        validationArray.push(this.$refs.title.validate());
        validationArray.push(this.$refs.firstName.validate());
        validationArray.push(this.$refs.lastName.validate());
      }
      if (this.user.profile.type === "business") {
        validationArray.push(this.$refs.ownerName.validate());
      }

      return validationArray.every(v => v === true);
    },
    async createUser() {
      this.user.profile.title = this.title;
      this.user.profile.address.countryId = this.user.profile.address.country.id;
      this.user.profile.address.regionId = this.user.profile.address.region
        ? this.user.profile.address.region.id
        : null;

      if (!this.user.profile.isPostalAddressSame) {
        this.user.profile.postalAddress.countryId = this.user.profile.postalAddress.country.id;
      } else {
        delete this.user.profile.postalAddress;
      }

      if (this.isValid()) {
        try {
          await this.$http.users.create(this.user);
          this.$q.notify({
            color: "positive",
            icon: "done",
            position: "top",
            message: "User Successfully Created",
            timeout: 1000
          });
        } catch (err) {
          if (err.response.data.message === "User address data is not valid.") {
            const country = this.countries.find(
              country => country.id === this.user.profile.address.country.id
            );
            this.showSuggestions = true;
            const form = {
              countryCode: country.code,
              city: this.user.profile.address.city,
              address: this.user.profile.address.address,
              postCode: this.user.profile.address.postCode
            };
            this.$http.users.getSuggestions(form).then(res => {
              this.suggestions = res.data;
            });
          }
          this.$q.notify({
            color: "negative",
            icon: "warning",
            position: "top",
            message: "Invalid Credentials",
            timeout: 1000
          });
        }
      }
    },
    rowclick(evt, row, index) {
      this.showSuggestions = false;
      this.user.profile.address.address = this.suggestions[
        index
      ].address.formattedAddress.join();
      this.user.profile.address.city = this.suggestions[index].address.locality;
      this.user.profile.address.postCode = this.suggestions[
        index
      ].address.postalCode;
      this.user.profile.address.countryId = this.suggestions[
        index
      ].address.country.code;
      if (!this.user.profile.isPostalAddressSame) {
        this.user.profile.postalAddress.address = this.suggestions[
          index
        ].address.formattedAddress.join();
        this.user.profile.postalAddress.city = this.suggestions[
          index
        ].address.locality;
        this.user.profile.postalAddress.postCode = this.suggestions[
          index
        ].address.postalCode;
        this.user.profile.postalAddress.countryId = this.suggestions[
          index
        ].address.country.code;
      }
    },
    changeTitle(val) {
      this.user.profile.title = val.value;
      this.title = val.label;
    },
    changeTypeValue(val) {
      this.user.profile.type = val.value;
      this.type = val.label;
    },
    selectCountry(country) {
      if (country.regions.length > 0) {
        this.regions = country.regions;
      }
    },
    selectRegion(region) {
      this.user.profile.address.regionId = region.id;
    }
  }
};
</script>
