<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.316"
    height="17.316"
    viewBox="0 0 11.09 12.164"
  >
    <path
      class="a"
      d="M5.921,4.725V.787a.787.787,0,0,0-1.575,0V4.725a.787.787,0,0,0,1.575,0ZM2.012,1.155a.591.591,0,0,1,.731.928,3.864,3.864,0,1,0,4.781,0,.591.591,0,1,1,.732-.928,5.045,5.045,0,1,1-6.244,0Z"
      transform="translate(-0.089)"
    />
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
.a {
  fill: #3f51b5;
}
</style>
