<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="17.316"
    height="17.316"
    viewBox="0 0 13.5 10.316"
  >
    <g transform="translate(0.15 0.15)">
      <path
        class="a"
        d="M.981,7.846a5.79,5.79,0,0,1,1.477-.924.483.483,0,0,1,.392.882,4.829,4.829,0,0,0-1.231.767,1.925,1.925,0,0,0-.652,1.447v.893a.483.483,0,0,0,.483.483h9.463a.483.483,0,0,0,.483-.483v-.893a1.925,1.925,0,0,0-.652-1.447A6.787,6.787,0,0,0,6.18,7.146,3.577,3.577,0,1,1,8.357,6.4,6.712,6.712,0,0,1,11.38,7.846a2.891,2.891,0,0,1,.98,2.172v.893a1.45,1.45,0,0,1-1.448,1.448H1.449A1.45,1.45,0,0,1,0,10.912v-.893a2.891,2.891,0,0,1,.98-2.172ZM8.788,3.573A2.607,2.607,0,1,0,6.18,6.18,2.61,2.61,0,0,0,8.788,3.573Zm0,0"
        transform="translate(0 0)"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style scoped>
.a {
  fill: #1a2d4c;
  stroke: #1a2d4c;
  stroke-width: 0.3px;
}
</style>
