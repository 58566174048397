import axios from "axios";
import headers from "./ApiHeaders";
import store from "../store";
import router from "../router";

const instance = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 30000
});

instance.interceptors.request.use(config => {
  config.headers = headers();
  return config;
});

instance.interceptors.response.use(
  response => response,
  error => {
    if (error.response.data.message === "Unauthorized") {
      store.commit("USER_LOGOUT");
      router.push("/login");
    }
    throw error;
  }
);

export default {
  auth: {
    login(form) {
      return instance({
        url: "auth/login",
        method: "post",
        data: form
      });
    }
  },
  media: {
    create(form) {
      return instance({
        url: "media",
        method: "post",
        data: form
      });
    }
  },
  pricing: {
    get() {
      return instance({
        url: "pricing",
        method: "get"
      });
    },
    getCurrencies() {
      return instance({
        url: "metadata/currencies",
        method: "get"
      });
    },
    getById(id) {
      return instance({
        url: `pricing/${id}`,
        method: "get"
      });
    },
    edit(id, form) {
      return instance({
        url: `pricing/${id}`,
        method: "put",
        data: form
      });
    }
  },
  tags: {
    get(filter, text, pageNumber, limit) {
      let query = "";
      if (filter && filter == "Description") {
        query =
          "?filter=label%7C%7C%24cont%7C%7C" +
          text +
          "&limit=" +
          limit +
          "&page=" +
          pageNumber;
      } else if (filter && filter == "Class") {
        query =
          "?filter=class.id%7C%7C%24eq%7C%7C" +
          text +
          "&limit=" +
          limit +
          "&page=" +
          pageNumber;
      }
      return instance({
        url: "tag" + query,
        method: "get"
      });
    },
    getTagById(id) {
      return instance({
        url: `tag/${id}`,
        method: "get"
      });
    },
    updateTag(id, data) {
      return instance({
        url: `tag/${id}`,
        method: "put",
        data: data
      });
    },
    uplaodCsv(file) {
      return instance({
        url: "tag/upload-csv",
        method: "post",
        data: file
      });
    },
    delete(id) {
      return instance({
        url: `tag/${id}`,
        method: "delete"
      });
    }
  },
  users: {
    get(qb) {
      return instance({
        url: "user?" + qb.query(),
        method: "get"
      });
    },
    getAdminUsers() {
      return instance({
        url: "user?filter=role.key||$eq||admin",
        method: "get"
      });
    },
    getCountries() {
      return instance({
        url: "metadata/countries",
        method: "get"
      });
    },
    getById(id) {
      return instance({
        url: `user/${id}`,
        method: "get"
      });
    },
    edit(id, form) {
      return instance({
        url: `user/${id}`,
        method: "put",
        data: form
      });
    },
    getSuggestions(form) {
      return instance({
        url: "/metadata/address-suggestion/by-address",
        method: "get",
        params: form
      });
    },
    create(form) {
      return instance({
        url: "user",
        method: "post",
        data: form
      });
    },
    createAdmin(form) {
      return instance({
        url: "user/admin",
        method: "post",
        data: form
      });
    },
    delete(id) {
      return instance({
        url: `user/${id}`,
        method: "delete"
      });
    },
    setPass(id, form) {
      return instance({
        url: `user/change-password/${id}`,
        method: "put",
        data: form
      });
    },
    changePass(form) {
      return instance({
        url: `account/change-password`,
        method: "put",
        data: form
      });
    },
    changeRole(id, form) {
      return instance({
        url: `user/${id}`,
        method: "put",
        data: form
      });
    }
  },
  trademarks: {
    get(qb) {
      return instance({
        url: "trademark?" + qb.query(),
        method: "get"
      });
    },
    getById(id) {
      return instance({
        url: `trademark/${id}`,
        method: "get"
      });
    }
  },
  coupons: {
    get(qb) {
      return instance({
        url: "coupon?" + qb.query(),
        method: "get"
      });
    },
    create(form) {
      return instance({
        url: "coupon",
        method: "post",
        data: form
      });
    },
    delete(id) {
      return instance({
        url: `coupon/${id}`,
        method: "delete"
      });
    },
    getById(id) {
      return instance({
        url: `coupon/${id}`,
        method: "get"
      });
    },
    edit(id, form) {
      return instance({
        url: `coupon/${id}`,
        method: "patch",
        data: form
      });
    },
    assignToUser(data) {
      return instance({
        url: "coupon/users/assign",
        method: "post",
        data: data
      });
    },
    unassignUser(data) {
      return instance({
        url: "coupon/users/unassign",
        method: "delete",
        data: data
      });
    }
  },
  renewals: {
    get(qb) {
      return instance({
        url: "renewal-crud?" + qb.query(),
        method: "get"
      });
    },
  }
};
