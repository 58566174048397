<template>
  <div class="settings pos-relative">
    <breadcrumb current="Settings"> </breadcrumb>
    <div class="row settings-section justify-between items-center">
      <div class="row actions">
        <q-btn
          class="q-mb-md add-reward"
          color="primary"
          :label="$t('settings.create_new')"
          @click="userDialog = true"
        />
      </div>
    </div>
    <q-table
      :columns="columns"
      :data="data"
      class="users-table"
      row-key="name"
      hide-pagination
      :pagination="{
        rowsPerPage: 100
      }"
    >
      <template v-slot:body-cell-action="props">
        <q-td :props="props">
          <q-btn-dropdown outline class="action-button" label="Action">
            <q-list class="setting-menu">
              <q-item
                clickable
                v-close-popup
                class="border-bottom"
                @click="openPassDialog(props.row)"
              >
                <q-item-section>
                  <q-item-label>{{
                    $t("settings.change_password")
                  }}</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </q-td>
      </template>
      <template v-slot:body-cell-createdAt="props">
        <q-td :props="props">
          {{ getDate(user.createdAt) }}
        </q-td>
      </template>
      <template v-slot:body-cell-role="props">
        <q-td :props="props">
          <b class="text-primary text-uppercase">
            {{ props.row.role.label }}
          </b>
        </q-td>
      </template>
    </q-table>
    <div class="users-table-mobile">
      <div class="users-table-header-mobile">
        #user
      </div>
      <q-list separator>
        <q-item
          class="user-list-item"
          v-for="(user, index) in data"
          :key="index"
        >
          <div class="row user-block">
            <div class="col-2 user-id">{{ user.id }}</div>
            <div class="col-10">
              <q-btn-dropdown
                unelevated
                class="action-button"
                label="Action"
                v-if="user.id !== $store.state.user.id"
              >
                <q-list class="setting-menu">
                  <q-item
                    clickable
                    v-close-popup
                    class="border-bottom"
                    @click="openPassDialog(user)"
                  >
                    <q-item-section>
                      <q-item-label>{{
                        $t("settings.change_password")
                      }}</q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>
            </div>
          </div>
        </q-item>
      </q-list>
    </div>
    <q-dialog
      v-if="user"
      v-model="passDialog"
      transition-show="flip-down"
      transition-hide="flip-up"
      content-class="pass-dialog"
    >
      <q-card>
        <q-bar>
          <h3 class="text-white text-uppercase">
            {{ user.profile.firstName + " " + user.profile.lastName }}
          </h3>
          <q-space />
          <q-btn dense flat icon="close" color="white" v-close-popup> </q-btn>
        </q-bar>

        <q-card-section>
          <h3 class="q-my-none text-weight-medium">
            {{ $t("settings.change_password") }}
          </h3>
          <p class="validation-text q-mt-xs">
            {{ $t("settings.password_validation") }}
          </p>
          <q-form ref="passForm" class="pass-form">
            <div class="row q-col-gutter-md">
              <div class="col-12">
                <q-input
                  v-model="passwordForm.newPassword"
                  class="q-mt-md"
                  outlined
                  :rules="[validatePassword || 'Please type something']"
                  :type="isPwd1 ? 'password' : 'text'"
                  :placeholder="$t('settings.new_password')"
                >
                  <template v-slot:prepend>
                    <img src="@/assets/lock.svg" alt="Lock" />
                  </template>
                  <template v-slot:append>
                    <q-icon
                      :name="isPwd1 ? 'visibility_off' : 'visibility'"
                      class="cursor-pointer"
                      @click="isPwd1 = !isPwd1"
                    />
                  </template>
                </q-input>
              </div>
              <div class="col-12">
                <q-input
                  v-model="passwordForm.confirmNewPassword"
                  outlined
                  :rules="[
                    val =>
                      (val &&
                        validatePassword &&
                        val === passwordForm.newPassword) ||
                      'Please type something'
                  ]"
                  :type="isPwd2 ? 'password' : 'text'"
                  :placeholder="$t('settings.confirm_password')"
                >
                  <template v-slot:prepend>
                    <img src="@/assets/lock.svg" alt="Lock" />
                  </template>
                  <template v-slot:append>
                    <q-icon
                      :name="isPwd2 ? 'visibility_off' : 'visibility'"
                      class="cursor-pointer"
                      @click="isPwd2 = !isPwd2"
                    />
                  </template>
                </q-input>
              </div>
            </div>
            <q-btn
              class="float-right q-mt-lg"
              color="primary"
              :label="$t('submit')"
              @click="setPass"
            />
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog
      v-model="userDialog"
      content-class="user-dialog"
      transition-show="flip-down"
      transition-hide="flip-up"
    >
      <q-card>
        <q-bar>
          <h3 class="text-white text-uppercase">
            {{ $t("settings.create_new") }}
          </h3>
          <q-space />
          <q-btn dense flat icon="close" color="white" v-close-popup> </q-btn>
        </q-bar>
        <q-card-section>
          <h3 class="q-mt-none text-weight-medium">
            {{ $t("settings.add_user") }}
          </h3>
          <q-form ref="userForm" class="user-form">
            <div class="row q-col-gutter-md">
              <div class="col-6">
                <q-input
                  outlined
                  v-model="newUser.profile.firstName"
                  class="input-complex"
                  :rules="[
                    val => (val && val.length > 0) || 'Please type something'
                  ]"
                  :placeholder="$t('settings.first_name')"
                >
                  <template v-slot:prepend>
                    <img src="@/assets/user-profile.svg" alt="First Name" />
                  </template>
                </q-input>
              </div>

              <div class="col-6">
                <q-input
                  outlined
                  v-model="newUser.profile.lastName"
                  class="input-complex"
                  :placeholder="$t('settings.last_name')"
                  :rules="[
                    val => (val && val.length > 0) || 'Please type something'
                  ]"
                >
                  <template v-slot:prepend>
                    <img src="@/assets/user-profile.svg" alt="Last Name" />
                  </template>
                </q-input>
              </div>
              <div class="col-12">
                <q-select
                  filled
                  v-model="newUser.profile.title"
                  :options="titles"
                  label="Title"
                  ref="title"
                  @input="changeTitle"
                  :rules="[requiredByTypeRule('Person')]"
                />
              </div>
              <div class="col-12">
                <q-input
                  outlined
                  v-model="newUser.email"
                  class="input-complex"
                  type="email"
                  :rules="[val => !!val || 'Email is missing', isValidEmail]"
                  :placeholder="$t('settings.email')"
                >
                  <template v-slot:prepend>
                    <img src="@/assets/email.svg" alt="Email" />
                  </template>
                </q-input>
              </div>
            </div>
            <q-btn
              class="float-right q-mt-lg"
              color="primary"
              :label="$t('submit')"
              @click="create"
            />
          </q-form>
        </q-card-section>
      </q-card>
    </q-dialog>
    <q-dialog
      v-model="successDialog"
      content-class="success-dialog"
      transition-show="flip-down"
      transition-hide="flip-up"
    >
      <q-card>
        <q-bar>
          <h3 class="text-white text-uppercase">
            {{ $t("success") }}
          </h3>
          <q-space />
          <q-btn dense flat icon="close" color="white" v-close-popup> </q-btn>
        </q-bar>

        <q-card-section>
          <h3 class="q-mt-none q-mb-sm text-weight-medium">
            {{ $t("settings.new_user") }}
          </h3>
          <span>
            {{ $t("settings.credentials") }}
          </span>
          <div class="row q-mt-lg items-center">
            <img width="75" src="@/assets/profile.svg" alt="Profile" />
            <div class="q-ml-lg">
              <div class="credentials">
                {{ $t("settings.username") }}:
                <a :href="`mailto:${currentUser.email}`"
                  ><b>{{ currentUser.email }}</b></a
                >
              </div>
              <div class="credentials">
                {{ $t("settings.password") }}: <b>{{ user.password }}</b>
              </div>
            </div>
          </div>
          <div>
            <q-btn
              class="float-right q-mt-lg q-ml-lg"
              color="primary"
              :label="$t('done')"
              @click="this.currentUser = {}"
              v-close-popup
            />
            <q-btn
              class="float-right q-mt-lg text-black btn-copy"
              outline
              color="white"
              @click="doCopy"
            >
              <img src="@/assets/copy.svg" alt="Copy" />
              Copy to clipboard
            </q-btn>
          </div>
        </q-card-section>
      </q-card>
    </q-dialog>
    <div class="spinner-overlay" v-if="loading">
      <q-spinner color="primary" size="3em" />
    </div>
  </div>
</template>

<script>
import Breadcrumb from "../components/Breadcrumb";
import copy from "copy-html-to-clipboard";
import generator from "random-passwords-generator";
import moment from "moment";
import { requiredByType } from "../utils/validations";
export default {
  components: {
    Breadcrumb
  },
  data() {
    return {
      grid: true,
      userDialog: false,
      successDialog: false,
      passDialog: false,
      loading: true,
      isPwd1: true,
      isPwd2: true,
      requiredByTypeRule: () => {},
      newUser: {
        profile: {
          firstName: "",
          lastName: "",
          title: "",
          type: "person",
          isPostalAddressSame: true
        },
        role: "admin",
        email: null,
        password: null,
        isActivated: false
      },
      user: {
        role: "admin",
        email: null,
        password: null,
        isActivated: false,
        profile: {
          firstName: "",
          lastName: "",
          createdAt: ""
        }
      },
      title: "",
      titles: [
        {
          label: "MR",
          value: "MR"
        },
        {
          label: "MS",
          value: "MS"
        },
        {
          label: "MRS",
          value: "MRS"
        },
        {
          label: "MISS",
          value: "MISS"
        },
        {
          label: "MX",
          value: "MX"
        },
        {
          label: "MASTER",
          value: "MASTER"
        }
      ],
      passwordForm: {
        newPassword: "",
        confirmNewPassword: ""
      },
      currentUser: {},
      newRole: null,
      model: "admin",
      columns: [
        {
          name: "id",
          required: true,
          label: "#user",
          field: item => item.id,
          align: "center"
        },
        {
          name: "email",
          required: true,
          label: "Email",
          field: item => item.email,
          align: "left"
        },
        {
          name: "firstName",
          required: true,
          label: "First Name",
          field: item => item.profile.firstName,
          align: "left"
        },
        {
          name: "lastName",
          required: true,
          label: "Last Name",
          field: item => item.profile.lastName,
          align: "left"
        },
        {
          name: "role",
          required: true,
          label: "Role",
          field: item => item.role,
          align: "left"
        },
        {
          name: "createdAt",
          required: true,
          label: "Creation Date",
          align: "left"
        },
        {
          name: "action",
          required: true,
          label: "Action",
          align: "left"
        }
      ],
      data: []
    };
  },
  created() {
    const self = this;
    self.get();
    this.requiredByTypeRule = requiredByType.bind(this);
    this.$http.users.getById(this.$store.state.user.id).then(res => {
      this.user = res.data;
    });
  },
  methods: {
    doCopy() {
      const data = this.currentUser;
      copy(
        `Hello ${data.profile.firstName} ${data.profile.lastName}, <br> <br> You have a new account at TMjam Dashboard <br> Account details: <br> <br> Username: <br> ${data.email} <br> <br> Password: <br> ${this.user.password} <br> <br> Set your password after sign in by clicking the link below. <br> <a href="https://tmjam.com/" target="_blank">https://tmjam.com/</a>`,
        {
          asHtml: true
        }
      );
    },
    validatePassword() {
      const reg = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
      );
      return reg.test(this.passwordForm.newPassword);
    },
    get() {
      const self = this;
      this.$http.users
        .getAdminUsers()
        .then(function(res) {
          setTimeout(function() {
            self.data = res.data;
            self.loading = false;
          }, 1000);
        })
        .catch(function(err) {
          self.$q.notify({
            color: "negative",
            icon: "warning",
            position: "top",
            message: err.response.data.message,
            timeout: 3000
          });
        });
    },
    setPass() {
      const self = this;
      this.$refs.passForm.validate().then(success => {
        if (success) {
          self.$http.users
            .setPass(this.currentUser.id, this.passwordForm)
            .then(function() {
              self.passDialog = false;
              self.currentUser = {};
            });
        }
      });
    },
    openPassDialog(user) {
      this.passDialog = true;
      this.currentUser = user;
    },
    openRoleDialog(user) {
      this.roleDialog = true;
      this.currentUser = user;
      this.newRole = user.role;
    },
    getDate(dt) {
      const date = new Date(dt);
      return moment(date).format("DD.MM.YYYY");
    },
    changeTitle(val) {
      this.newUser.profile.title = val.value;
      this.title = val.label;
    },
    async create() {
      this.$refs.userForm.validate().then(success => {
        if (success) {
          const self = this;
          this.generate();
          this.newUser.role = "admin";
          this.$http.users
            .createAdmin(this.newUser)
            .then(function(res) {
              const data = res.data;
              self.userDialog = false;
              self.successDialog = true;
              self.data.push(data);
              self.currentUser = res.data;
              self.newUser.profile.firstName = null;
              self.newUser.profile.lastName = null;
              self.newUser.role = "admin";
              self.newUser.email = null;
            })
            .catch(function(err) {
              self.$q.notify({
                color: "negative",
                icon: "warning",
                position: "top",
                message: err.response.data.message,
                timeout: 3000
              });
            });
        }
      });
    },
    isValidEmail(val) {
      const emailPattern = /^(?=[a-zA-Z0-9@._%+-]{6,254}$)[a-zA-Z0-9._%+-]{1,64}@(?:[a-zA-Z0-9-]{1,63}\.){1,8}[a-zA-Z]{2,63}$/;
      return emailPattern.test(val) || "Invalid email";
    },
    async generate() {
      const options = {
        LENGTH: 8,
        ALLOW_ALPHABETS_LOWERCASE: true,
        ALLOW_ALPHABETS_UPPERCASE: true,
        ALLOW_NUMBERS: true,
        ALLOW_SPECIAL_CHARACTERS: true,
        EXCEPTIONS: "",
        FIRST_CHARACTER_LOWERCASE: false,
        FIRST_CHARACTER_UPPERCASE: true,
        FIRST_CHARACTER_NUMBER: false,
        FIRST_CHARACTER_SPECIAL_CHARACTER: false,
        MIN_ALPHABETS_LOWERCASE: 2,
        MIN_ALPHABETS_UPPERCASE: 2,
        MIN_NUMBERS: 2,
        MIN_SPECIAL_CHARACTERS: 2
      };

      this.user.password = generator(options);
      this.newUser.password = this.user.password
    }
  }
};
</script>
